import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

type PowerSearchRoutePathParams = {
  // no path params for now
};

type PowerSearchRouteQueryParams = {
  startDate: string;
  endDate: string;
};

export const powerSearchResultAdvancedRoute = new AppRouteLazyBuilder<
  PowerSearchRoutePathParams,
  PowerSearchRouteQueryParams
>({
  path: "/power-search/advanced/result",
  lazy: async () => {
    const { PowerSearchResultAdvancedPage } = await import(
      "./PowerSearchResultAdvanced.page"
    );
    return { Component: PowerSearchResultAdvancedPage };
  },
});

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";
import { PermissionGuard } from "src/components/PermissionGuard";
import { pageNotFoundRoute } from "../PageNotFound/PageNotFound.route";
import { accountManagementAllowedRoles } from "../AccountsManagementEditAccount/AccountsManagementEditAccount.route";

export type AccountsManagementAddUserParams = {
  accountId: string;
};

export const accountsManagementAddUserRoute =
  new AppRouteLazyBuilder<AccountsManagementAddUserParams>({
    path: "/accounts-management/:accountId/add-user",
    lazy: async () => {
      const { AccountsManagementAddUserPage } = await import(
        "./AccountsManagementAddUser.page"
      );
      return {
        Component: function () {
          return (
            <PermissionGuard
              component={AccountsManagementAddUserPage}
              allowedRoles={accountManagementAllowedRoles}
              disableMobile
              redirectTo={pageNotFoundRoute.path}
              silent
            />
          );
        },
      };
    },
  });

import { ComponentType, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ROLES } from "src/utils/useUserRoleList";
import { useSessionContext } from "src/api/useSessionContext";

export function PermissionGuard({
  component: Component,
  redirectTo = "/",
  silent,
  disableMobile,
  allowedRoles,
}: {
  component: ComponentType;
  redirectTo?: string;
  silent?: boolean;
  allowedRoles: Array<(typeof ROLES)[number]>;
  disableMobile?: boolean;
}) {
  const navigate = useNavigate();

  const { breakpoints } = useTheme();
  const { roles } = useSessionContext();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const isEnabled = allowedRoles.some((r) => roles.includes(r));

  useEffect(() => {
    if (disableMobile && isMobile) {
      navigate(redirectTo);
    }
  }, [navigate, redirectTo, disableMobile, isMobile]);

  useEffect(() => {
    if (!isEnabled && redirectTo && silent) {
      navigate(redirectTo);
    }
  }, [navigate, redirectTo, isEnabled, silent]);

  if (isEnabled) {
    return <Component />;
  }

  if (silent) {
    return null;
  }

  return (
    <Dialog open>
      <DialogTitle>Permission Guard</DialogTitle>
      <DialogContent>
        <Box
          m="auto"
          display="flex"
          flexGrow={1}
          flexDirection="column"
          justifyContent="center"
        >
          <Typography variant="subtitle1">
            This feature requires higher role permission.
          </Typography>
          {process.env.NODE_ENV === "development" && (
            <Typography variant="body2">
              Check the role of the current user. Requires a User Manager role
              or higher.
            </Typography>
          )}
          <Button onClick={() => navigate(redirectTo)}>OK</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

import React from "react";
import { DarkMode, LightMode } from "@mui/icons-material";
import {
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  Typography,
  useTheme,
} from "@mui/material";

import { useAppThemeSwitchContext } from "src/providers/AppThemeProvider";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";

export const ThemeSwitch = (props: { collapsed?: boolean }) => {
  const theme = useTheme();
  const { mode, setPaletteMode } = useAppThemeSwitchContext();

  const toggleThemeMode = () => {
    if (mode === "light") {
      setPaletteMode("dark");
    }

    if (mode === "dark") {
      setPaletteMode("light");
    }
  };

  const setPaletteLight = () => setPaletteMode("light");
  const setPaletteDark = () => setPaletteMode("dark");

  const transitionOptions = props.collapsed
    ? theme.mixins.drawer.collapsed.transition
    : theme.mixins.drawer.expanded.transition;

  return (
    <TooltipBase placement="right" title="Switch theme do Dark|Light">
      <ToggleButtonGroup
        color="primary"
        value={mode}
        exclusive
        fullWidth
        size="small"
        sx={{
          width: "unset",
          alignSelf: "stretch",
          overflow: "hidden",
          height: props.collapsed ? 48 : 36,

          marginLeft: props.collapsed ? 0 : 1.5,
          marginRight: props.collapsed ? 0 : 1.5,
          marginTop: props.collapsed ? 0 : 0.75,
          marginBottom: props.collapsed ? 0 : 0.75,
          padding: props.collapsed ? 0 : theme.spacing(0.5),

          borderWidth: props.collapsed ? 0 : 1,
          borderStyle: "solid",
          borderColor: props.collapsed ? "transparent" : theme.palette.divider,
          borderRadius: props.collapsed ? theme.spacing(1) : theme.spacing(0.5),
          transition: theme.transitions.create(
            [
              "margin-left",
              "margin-right",
              "margin-top",
              "margin-bottom",
              "height",
              "border-width",
              "border-color",
              "padding",
              "border-radius",
            ],
            transitionOptions
          ),
          [`.${toggleButtonClasses.root}`]: {
            overflow: "clip",
            border: "none",
          },
          [`.${toggleButtonGroupClasses.grouped}`]: {
            "&:not(:first-of-type)": {
              marginLeft: 0,
              borderLeft: 0,
              borderTopLeftRadius: theme.spacing(0.5),
              borderBottomLeftRadius: theme.spacing(0.5),
              borderTopRightRadius: theme.spacing(0.5),
              borderBottomRightRadius: theme.spacing(0.5),
            },
            "&:not(:last-of-type)": {
              borderTopLeftRadius: theme.spacing(0.5),
              borderBottomLeftRadius: theme.spacing(0.5),
              borderTopRightRadius: theme.spacing(0.5),
              borderBottomRightRadius: theme.spacing(0.5),
            },
          },
        }}
      >
        <ToggleButton
          value="light"
          selected={props.collapsed ? false : mode === "light"}
          onClick={props.collapsed ? toggleThemeMode : setPaletteLight}
          sx={{
            color: "text.secondary",
          }}
        >
          {props.collapsed && (
            <React.Fragment>
              {mode === "light" && <DarkMode />}
              {mode === "dark" && <LightMode />}
            </React.Fragment>
          )}

          {!props.collapsed && <LightMode />}

          <Typography
            variant="button"
            textTransform="none"
            align="left"
            sx={{
              overflow: "clip",
              ml: props.collapsed ? 0 : 1,
              opacity: props.collapsed ? 0 : 1,
              width: props.collapsed ? 0 : undefined,
              transition: theme.transitions.create(
                ["width", "opacity", "margin-left"],
                transitionOptions
              ),
            }}
          >
            Light
          </Typography>
        </ToggleButton>
        <ToggleButton
          value="dark"
          onClick={setPaletteDark}
          sx={{
            // collapse seconds button
            borderWidth: "none",
            color: "text.secondary",
            padding: props.collapsed ? 0 : undefined,
            width: props.collapsed ? 0 : undefined,
            opacity: props.collapsed ? 0 : 1,
            transition: theme.transitions.create(
              ["width", "margin-left", "opacity", "padding"],
              transitionOptions
            ),
          }}
        >
          <DarkMode />
          <Typography
            variant="button"
            textTransform="none"
            align="left"
            sx={{
              ml: 1,
              width: props.collapsed ? 0 : undefined,
              transition: theme.transitions.create(
                ["width", "margin-left"],
                transitionOptions
              ),
            }}
          >
            Dark
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </TooltipBase>
  );
};

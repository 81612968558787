import { useQueryParam } from "use-query-params";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Logo } from "src/components/logo/Logo";
import { TextInputBase } from "src/components/TextInputBase";
import { useUnsubscribeAll } from "src/api/useUnsubscribeAll";
import { useUnsubscribeAllAssociatedWith } from "src/api/useUnsubscribeAllAssociatedWith";
import { useUnsubscribeOne } from "src/api/useUnsubscribeOne";
import { AppLayoutPublicHeader } from "src/components/AppLayoutPublicHeader";
import { AppLink } from "src/components/AppLink/AppLink";
import {
  EventType,
  UnsubscribeAlertSuccessSection,
} from "./UnsubscribeAlertSuccessSection";

const unsubscribeReasons = {
  noReason: {
    value: "dontProvide",
    title: "Don’t want to provide the reason",
  },
  tooManyNotifications: {
    value: "lotNotifications",
    title: "I get a lot of notifications",
  },
  noLongerRelevant: {
    value: "noRelevant",
    title: "No longer relevant",
  },
  leaveComment: {
    value: "leaveComment",
    title: "Leave a comment",
  },
} as const;

const unsubscribeType = {
  alertForKeyword: {
    value: "alertForKeyword",
    title: "This alert",
  },
  allAlertsForKeyword: {
    value: "allAlertsForCurrentKeyword",
    title: "All Alerts for the current Keyword",
  },
  allAlertsAllKeywords: {
    value: "allAlertsForAllKeywords",
    title: "All Alerts for all Keywords",
  },
};

export const UnsubscribeAlertsPage = () => {
  const { palette, breakpoints } = useTheme();
  const { isAuthenticated } = useAuth0();

  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const [isSuccess, setIsSuccess] = useState(false);
  const [eventType, setEventType] = useState<EventType>("one");

  const unsubscribeAll = useUnsubscribeAll({
    onSuccess: () => {
      setIsSuccess(true);
      setEventType("all");
    },
  });
  const unsubscribeAllAssosiatedWith = useUnsubscribeAllAssociatedWith({
    onSuccess: () => {
      setIsSuccess(true);
      setEventType("allAssociatedWith");
    },
  });
  const unsubscribeOne = useUnsubscribeOne({
    onSuccess: () => {
      setIsSuccess(true);
      setEventType("one");
    },
  });

  const [alertId] = useQueryParam<string>("alertId");
  const [userId] = useQueryParam<string>("userId");
  const [queryId] = useQueryParam<string>("queryId");
  const [queryTitle] = useQueryParam<string>("title");

  const [reason, setReason] = useState<string>(
    unsubscribeReasons.noReason.value
  );

  const [comment, setComment] = useState("");

  const [alertType, setAlertType] = useState<string>("");
  const onTypeChange = (event: SelectChangeEvent) => {
    setAlertType(event.target.value);
  };

  useEffect(() => {
    if (reason !== unsubscribeReasons.leaveComment.value) {
      // reset comment when user picks predefined reason
      setComment("");
    }
  }, [reason]);

  const handleUnsubscribe = () => {
    const message =
      reason === unsubscribeReasons.leaveComment.value
        ? `${reason}: ${comment}` // send with comment
        : `${reason}`;

    if (alertType === unsubscribeType.allAlertsAllKeywords.value) {
      return unsubscribeAll.mutate({
        body: {
          reason: message,
        },
        params: {
          path: {
            userId,
          },
        },
      });
    }

    if (alertType === unsubscribeType.allAlertsForKeyword.value) {
      return unsubscribeAllAssosiatedWith.mutate({
        body: {
          reason: message,
        },
        params: {
          path: {
            userId,
            queryId,
          },
        },
      });
    }

    if (alertType === unsubscribeType.alertForKeyword.value) {
      return unsubscribeOne.mutate({
        body: {
          reason: message,
        },
        params: {
          path: {
            userId,
            queryId,
            alertId,
          },
        },
      });
    }
  };

  const mainContent = isSuccess ? (
    <UnsubscribeAlertSuccessSection
      queryTitle={queryTitle}
      type={eventType}
      onBack={() => setIsSuccess(false)}
    />
  ) : (
    <Stack mt={isMobile ? 1.5 : 6} width={346}>
      <Typography variant="h6" mb={1}>
        {`Mute Alert(s) for the "${queryTitle}" keyword`}
      </Typography>

      <FormControl fullWidth sx={{ mb: 1.5 }}>
        <InputLabel>Select alert(s) to mute</InputLabel>
        <Select
          value={alertType}
          label="Select alert(s) to mute"
          onChange={onTypeChange}
        >
          <MenuItem
            sx={{ height: 48 }}
            value={unsubscribeType.alertForKeyword.value}
          >
            {unsubscribeType.alertForKeyword.title}
          </MenuItem>

          <MenuItem
            sx={{ height: 48 }}
            value={unsubscribeType.allAlertsForKeyword.value}
          >
            {unsubscribeType.allAlertsForKeyword.title}
          </MenuItem>
          {isAuthenticated && (
            <MenuItem
              sx={{ height: 48 }}
              value={unsubscribeType.allAlertsAllKeywords.value}
            >
              {unsubscribeType.allAlertsAllKeywords.title}
            </MenuItem>
          )}
        </Select>
      </FormControl>

      <Typography variant="subtitle1">Why you want unsubscribe?</Typography>

      <RadioGroup
        sx={{ mt: 2 }}
        value={reason}
        onChange={(_, val) => setReason(val)}
      >
        <FormControlLabel
          value={unsubscribeReasons.noReason.value}
          label={unsubscribeReasons.noReason.title}
          control={<Radio />}
          sx={{ pl: 1, mx: 0 }}
        />
        <FormControlLabel
          value={unsubscribeReasons.tooManyNotifications.value}
          label={unsubscribeReasons.tooManyNotifications.title}
          control={<Radio />}
          sx={{ pl: 1, mx: 0 }}
        />
        <FormControlLabel
          value={unsubscribeReasons.noLongerRelevant.value}
          label={unsubscribeReasons.noLongerRelevant.title}
          control={<Radio />}
          sx={{ pl: 1, mx: 0 }}
        />
        <FormControlLabel
          value={unsubscribeReasons.leaveComment.value}
          label={unsubscribeReasons.leaveComment.title}
          control={<Radio />}
          sx={{ pl: 1, mx: 0 }}
        />
      </RadioGroup>
      {reason === unsubscribeReasons.leaveComment.value && (
        <TextInputBase
          id="comment-input"
          label="Comment"
          fullWidth
          multiline
          rows={4}
          placeholder="Comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          helperText={`${comment.length} / 200`}
        />
      )}
      <Button
        variant="contained"
        fullWidth
        sx={{ mt: isMobile ? 2 : 2.5 }}
        onClick={handleUnsubscribe}
        children="Mute"
      />
    </Stack>
  );

  return (
    <Stack bgcolor={palette.background.paper} height="100%">
      {!isAuthenticated && <AppLayoutPublicHeader />}
      <Stack mx="auto" pb={4} width="100%" alignItems="center">
        {isAuthenticated && (
          <Stack
            width="100%"
            height={56}
            alignItems="center"
            justifyContent="center"
            sx={{ backgroundColor: palette.background.default }}
          >
            <AppLink to={"/"}>
              <Logo height={34} />
            </AppLink>
          </Stack>
        )}
        {mainContent}
      </Stack>
    </Stack>
  );
};

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";
import { PermissionGuard } from "src/components/PermissionGuard";
import { pageNotFoundRoute } from "../PageNotFound/PageNotFound.route";
import { accountManagementAllowedRoles } from "../AccountsManagementEditAccount/AccountsManagementEditAccount.route";

export const accountsManagementRoute = new AppRouteLazyBuilder({
  path: "/accounts-management",
  lazy: async () => {
    const { AccountsManagementPage } = await import(
      "./AccountsManagement.page"
    );
    return {
      Component: function () {
        return (
          <PermissionGuard
            component={AccountsManagementPage}
            allowedRoles={accountManagementAllowedRoles}
            disableMobile
            redirectTo={pageNotFoundRoute.path}
            silent
          />
        );
      },
    };
  },
});

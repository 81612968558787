import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type ReportAnalysisRouteQueryParams = {
  //
};

export const reportAnalysisRoute = new AppRouteLazyBuilder<
  void,
  ReportAnalysisRouteQueryParams
>({
  path: "/report-analysis",
  lazy: async () => {
    const { ReportAnalysisPage } = await import("./ReportAnalysis.page");
    return { Component: ReportAnalysisPage };
  },
});

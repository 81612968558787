import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";
import { ReportDownloadFormat } from "src/api/useReportExport";

export type DownloadFilePathParams = {
  userId: string;
  id: string;
  type: ReportDownloadFormat;
};

export type DownloadFileQueryParams = {
  name: string;
};
export const downloadFileRoute = new AppRouteLazyBuilder<
  DownloadFilePathParams,
  DownloadFileQueryParams
>({
  path: "/download/:userId/:type/:id",
  lazy: async () => {
    const { DownloadFilePage } = await import("./DownloadFile.page");
    return { Component: DownloadFilePage };
  },
});

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type ReportRouteQueryParams = {
  pageTab?: string;
};

export const reportsRoute = new AppRouteLazyBuilder<
  void,
  ReportRouteQueryParams
>({
  path: "/reports",
  hideAppBar: true,
  lazy: async () => {
    const { ReportsPage } = await import("./Reports.page");
    return { Component: ReportsPage };
  },
});

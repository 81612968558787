import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicEventPathParams = {
  stationId: string;
  dateTime: string;
};

export type PublicEventQueryParams = {
  queryId: string;
};

export const publicEventRoute = new AppRouteLazyBuilder<
  PublicEventPathParams,
  PublicEventQueryParams
>({
  path: "/public/broadcast-datetime/:stationId/:dateTime/",
  lazy: async () => {
    const { PublicEventPage } = await import("./PublicEvent.page");
    return { Component: PublicEventPage };
  },
});

import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSessionContext } from "src/api/useSessionContext";
import { ConsentManagementResponse } from "src/api/useConsentManagement";
import { useConsentManagementUpdate } from "src/api/useConsentManagementUpdate";
import { DialogPromptProps } from "src/components/DialogPrompt/DialogPrompt.model";

type CustomizeConsentPreferencesDialogProps = Omit<
  DialogPromptProps,
  "title" | "footer" | "onConfirm"
> & {
  closeOnLeftSide?: boolean;
};

const defaultState = {
  permitNecessary: true,
  permitFunctional: true,
  permitAnalytics: true,
};

export const CustomizeConsentPreferencesDialog = ({
  onClose,
  open,
  closeOnLeftSide,
  ...props
}: CustomizeConsentPreferencesDialogProps) => {
  const { palette } = useTheme();

  const { userId, userConsent } = useSessionContext();

  const [selectedValues, setSelectedValues] =
    useState<ConsentManagementResponse>(defaultState);

  useEffect(() => {
    if (!userConsent) return;
    setSelectedValues(userConsent);
  }, [userConsent]);

  const updateConsentPreferences = useConsentManagementUpdate({
    options: {
      onSettled() {
        onClose && onClose();
      },
    },
  });

  const saveOptions = () => {
    updateConsentPreferences.mutate({
      path: {
        id: userId,
        permitNecessary:
          selectedValues.permitNecessary ?? defaultState.permitNecessary,
        permitFunctional:
          selectedValues.permitFunctional ?? defaultState.permitFunctional,
        permitAnalytics:
          selectedValues.permitAnalytics ?? defaultState.permitAnalytics,
      },
    });
  };

  const acceptDefault = () => {
    updateConsentPreferences.mutate({
      path: {
        id: userId,
        permitNecessary: defaultState.permitNecessary,
        permitFunctional: defaultState.permitFunctional,
        permitAnalytics: defaultState.permitAnalytics,
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Stack>
        <DialogTitle sx={{ px: 3, py: 2 }} columnGap={2}>
          {closeOnLeftSide && onClose && (
            <IconButton edge="end" onClick={onClose}>
              <Close />
            </IconButton>
          )}

          <Box>Customize Consent Preferences</Box>

          {!closeOnLeftSide && onClose && (
            <IconButton edge="end" onClick={onClose}>
              <Close />
            </IconButton>
          )}
        </DialogTitle>
        <Divider />
      </Stack>

      <DialogContent sx={{ py: 2 }}>
        <Box
          m="auto"
          display="flex"
          flexGrow={1}
          flexDirection="column"
          justifyContent="center"
        >
          <Stack>
            <Typography variant="body1" mb={2}>
              We use cookies to improve your browsing experience, deliver
              personalized ads and content, and analyze our traffic. By clicking
              “Accept Default”, you agree to our use of cookies.
            </Typography>
            <FormControlLabel
              checked={selectedValues?.permitNecessary}
              control={<Switch size="small" />}
              label={
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  <Typography variant="subtitle1">Necessary</Typography>
                  <Typography variant="caption" color="success.main">
                    Always Active
                  </Typography>
                </Stack>
              }
              sx={{ ml: 2, whiteSpace: "nowrap" }}
            />
            <Typography variant="body1" mb={2} ml={7}>
              Necessary cookies enable core features like secure login (Auth0),
              Authentication, and Load Balancer Cookies. They don’t store
              personal data.
            </Typography>

            <FormControlLabel
              checked={selectedValues?.permitFunctional}
              onChange={() =>
                setSelectedValues((pV) => ({
                  ...pV,
                  permitFunctional: !pV?.permitFunctional,
                }))
              }
              control={<Switch size="small" />}
              label={<Typography variant="subtitle1">Functional</Typography>}
              sx={{ ml: 2, whiteSpace: "nowrap" }}
            />
            <Typography variant="body1" mb={2} ml={7}>
              Functional cookies help the Insight application perform key
              duties, such as sharing and support. For example: Intercom,
              Hubspot.
            </Typography>

            <FormControlLabel
              checked={selectedValues?.permitAnalytics}
              onChange={() =>
                setSelectedValues((pV) => ({
                  ...pV,
                  permitAnalytics: !pV?.permitAnalytics,
                }))
              }
              control={<Switch size="small" />}
              label={<Typography variant="subtitle1">Analytics</Typography>}
              sx={{ ml: 2, whiteSpace: "nowrap" }}
            />
            <Typography variant="body1" mb={2} ml={7}>
              Analytical cookies, using tools like Hotjar and Google Analytics,
              track visitor activity, bounce rates, and traffic sources to
              enhance your experience.
            </Typography>
          </Stack>
          <Stack flexDirection="row" justifyContent="flex-end" gap={2} pt={2}>
            <Button color="error" onClick={onClose}>
              Close
            </Button>
            <Button
              sx={{ color: palette.text.primary }}
              onClick={saveOptions}
              variant="outlined"
            >
              Save My Options
            </Button>
            <Button variant="contained" onClick={acceptDefault}>
              Accept Default
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type WatchListPathParams = {
  queryId: string;
};

export type WatchListQueryParams = {
  startDateTime: string;
  endDateTime: string;
} | void;

export const watchListTermResultsRoute = new AppRouteLazyBuilder<
  WatchListPathParams,
  WatchListQueryParams
>({
  path: "/watchlist/:queryId",
  lazy: async () => {
    const { WatchListTermResultsPage } = await import(
      "./WatchListTermResults.page"
    );
    return { Component: WatchListTermResultsPage };
  },
});

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";
import { PermissionGuard } from "src/components/PermissionGuard";
import { USER_ROLE } from "src/utils/useUserRoleList";

export type UserManagementPathParam = {
  userId: string;
};

export const userManagementUpdateRoute =
  new AppRouteLazyBuilder<UserManagementPathParam>({
    path: "/user-management/update/:userId",
    lazy: async () => {
      const { UserManagementUpdatePage } = await import(
        "./UserManagementUpdate.page"
      );
      return {
        Component: function () {
          return (
            <PermissionGuard
              component={UserManagementUpdatePage}
              allowedRoles={[
                USER_ROLE.Admin,
                USER_ROLE.AccountManager,
                USER_ROLE.UserManager,
              ]}
              disableMobile
              redirectTo="/"
            />
          );
        },
      };
    },
  });

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PowerSearchResultClipEditorPathParam = {
  eventId: string;
};

export const powerSearchResultClipEditorRoute =
  new AppRouteLazyBuilder<PowerSearchResultClipEditorPathParam>({
    path: "/power-search/result/:eventId/edit",
    lazy: async () => {
      const { PowerSearchResultClipEditorPage } = await import(
        "./PowerSearchResultClipEditor.page"
      );
      return { Component: PowerSearchResultClipEditorPage };
    },
  });

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";
import { SettingsPageTab } from "./Settings.model";

type SettingsRouteQueryParams = {
  pageTab?: SettingsPageTab;
};

export const settingsRoute = new AppRouteLazyBuilder<
  void,
  SettingsRouteQueryParams | void
>({
  path: "/settings",
  lazy: async () => {
    const { SettingsPage } = await import("./Settings.page");
    return { Component: SettingsPage };
  },
});

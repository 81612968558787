import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicReportEventPathParams = {
  reportId: string;
  eventId: string;
};

export const publicReportEventRoute =
  new AppRouteLazyBuilder<PublicReportEventPathParams>({
    path: "/public/report/:reportId/:eventId",
    lazy: async () => {
      const { PublicReportEventPage } = await import(
        "./PublicReportEvent.page"
      );
      return { Component: PublicReportEventPage };
    },
  });

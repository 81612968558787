import { useCallback, useEffect } from "react";
import {
  Alert,
  alertClasses,
  Button,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { showNewMessage } from "@intercom/messenger-js-sdk";
import ErrorIcon from "@mui/icons-material/Error";
import { differenceInDays } from "date-fns";
import { useSessionContext } from "src/api/useSessionContext";
import { useOpenState } from "src/utils/useOpenState";
import { useLocalStorage } from "src/utils/useLocalStorage";
import { isExternalToolAvailable } from "src/utils/makeUserConsentPermission";
import { intercomButtonId } from "src/providers/IntercomProvider";

const today = new Date();

export const accountNoticeKey = "accountNoticeWasShown";

export const AccountExpirationNotice = () => {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const openState = useOpenState();
  const [noticeWasShown, setNoticeWasShown] = useLocalStorage(accountNoticeKey);
  const { expiry, userConsent } = useSessionContext();

  const daysTillExpire = expiry ? differenceInDays(new Date(expiry), today) : 0;

  useEffect(() => {
    if (noticeWasShown) return;

    if (
      expiry &&
      daysTillExpire < 16 &&
      daysTillExpire > 0 &&
      !openState.isOpen
    ) {
      openState.show();
    }
  }, [expiry, openState, daysTillExpire, noticeWasShown, setNoticeWasShown]);

  const handleClose = useCallback(() => {
    openState.hide();
    setNoticeWasShown("true");
  }, [openState, setNoticeWasShown]);

  const contactSupport = () => {
    setTimeout(() => {
      showNewMessage("How can I renew my account?");
    }, 100);
  };

  const isIntercomAvailable = isExternalToolAvailable(
    "permitFunctional",
    userConsent
  );

  return (
    <Snackbar
      open={openState.isOpen}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        severity="error"
        variant="filled"
        icon={<ErrorIcon />}
        onClose={handleClose}
        sx={{
          borderRadius: "8px",
          alignItems: isMobile ? "flex-start" : "center",
          backgroundColor: palette.text.primary,
          width: { lg: "892px" },
          [`.${alertClasses.action}`]: {
            pt: isMobile ? "4px" : 0,
            pl: 0,
          },
          [`.${alertClasses.message}`]: {
            p: 0,
            py: isMobile ? 1 : 0,
          },
        }}
      >
        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-start" : "center"}
        >
          <Typography variant="body2">
            Your account will expire in
            <Typography
              component={"span"}
              fontWeight={600}
              letterSpacing="0.1px"
            >
              {" "}
              {daysTillExpire} days.{" "}
            </Typography>
            {!isMobile && (
              <Typography component={"span"} variant="body2">
                To continue using the service, please contact TVEyes.
              </Typography>
            )}
          </Typography>
          {isIntercomAvailable && (
            <Button
              id={intercomButtonId}
              sx={{
                py: 0,
                px: isMobile ? 0 : 2,
                ml: isMobile ? 0 : 1,
                minWidth: "fit-content",
                letterSpacing: "0.1px",
                fontWeight: 600,
                color: palette.secondary.main,
              }}
              onClick={contactSupport}
            >
              Contact Support
            </Button>
          )}
        </Stack>
      </Alert>
    </Snackbar>
  );
};

import { Stack } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { Outlet } from "react-router-dom";
import { AppQueryParamsProvider } from "src/providers/AppQueryParamsProvider";
import { usePushNotificationEvent } from "src/utils/usePushNotificationEvent";
import { DemoAndImpersonateLayout } from "src/DemoAndImpersonateLayout";
import { ExportCompleteNotificationActions } from "./ExportCompleteNotificationActions/ExportCompleteNotificationActions";

export const AppLayoutPublic = () => {
  usePushNotificationEvent({
    key: "ExportComplete",
    cb: (notification) => {
      if (notification.additionalData) {
        const reportDataObj = JSON.parse(notification.additionalData);
        enqueueSnackbar(
          `${reportDataObj.type?.toUpperCase()} is ready: '${
            reportDataObj.title
          }'`,
          {
            variant: "success",
            action: (snackbarId) => (
              <ExportCompleteNotificationActions
                userId={notification.userId}
                reportDataObj={reportDataObj}
                snackbarId={snackbarId}
              />
            ),
            autoHideDuration: 15000,
            key: reportDataObj.title,
          }
        );
      }
    },
  });

  return (
    <AppQueryParamsProvider>
      <DemoAndImpersonateLayout />
      <Stack id="app-layout-public-root" width="100%" height="100%">
        <Outlet />
      </Stack>
    </AppQueryParamsProvider>
  );
};

import { components } from "@tveyes/twosionwebapischema";

type consentDataType = components["schemas"]["UserConsent"];
type keyType = keyof Omit<consentDataType, "id" | "modifiedDate">;

export function isExternalToolAvailable(
  type: keyType,
  consentData?: consentDataType | null
) {
  if (consentData === undefined) return false;
  if (consentData === null) return true;

  return consentData[type];
}

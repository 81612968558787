import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export const publicTermsOfServiceRoute = new AppRouteLazyBuilder({
  path: "/public/terms-of-service",
  lazy: async () => {
    const { PublicTermsOfServicePage } = await import(
      "./PublicTermsOfService.page"
    );
    return { Component: PublicTermsOfServicePage };
  },
});

import {
  LazyRouteFunction,
  LoaderFunction,
  RouteObject,
} from "react-router-dom";
import { makeAppRouteUrl } from "src/utils/makeAppRouteUrl";

/**
 * Defines app route builder used to type check app route parameters
 */
export class AppRouteLazyBuilder<
  AppRoutePathParams extends void | Record<string, string | number> = void,
  AppRouteQueryParams extends void | Record<
    string,
    string | number | (string | number)[] | boolean | null
  > = void
> {
  readonly id: string;
  readonly path: string;
  readonly lazy: LazyRouteFunction<RouteObject>;
  readonly children?: RouteObject[];
  readonly hideAppBar: boolean;

  readonly loader?: LoaderFunction;

  makeUrl(path: AppRoutePathParams, query: AppRouteQueryParams) {
    return makeAppRouteUrl(this.path, { path, query });
  }

  constructor({
    id,
    path,
    lazy,
    children,
    hideAppBar,
    loader,
  }: {
    id?: string;
    path: string;
    lazy: LazyRouteFunction<RouteObject>;
    children?: RouteObject[];
    hideAppBar?: boolean;
    loader?: LoaderFunction;
  }) {
    this.id = id ?? path;
    this.path = path;
    this.lazy = lazy;
    this.children = children;
    this.hideAppBar = !!hideAppBar;
    this.loader = loader;
  }
}

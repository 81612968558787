import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type WatchListTermResultClipEditorPathParam = {
  queryId: string;
  eventId: string;
};

export const watchListTermResultClipEditorRoute =
  new AppRouteLazyBuilder<WatchListTermResultClipEditorPathParam>({
    path: "/watchlist/:queryId/:eventId/edit",
    lazy: async () => {
      const { WatchListTermResultClipEditorPage } = await import(
        "./WatchListTermResultClipEditor.page"
      );
      return { Component: WatchListTermResultClipEditorPage };
    },
  });

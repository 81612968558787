import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";
import { EntitlementGuard } from "src/components/EntitlementGuard";

export const featureGateDemoRoute = new AppRouteLazyBuilder({
  path: "/feature-gate-demo",
  lazy: async () => {
    const { FeatureGateDemoPage } = await import("./FeatureGateDemo.page");

    return {
      Component: function () {
        return (
          <EntitlementGuard
            component={FeatureGateDemoPage}
            entitlementKey="enableEditWatchList"
            allow={() => false}
            redirectTo="/"
            // silent
          />
        );
      },
    };
  },
});

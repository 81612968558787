import { USER_ROLE } from "src/utils/useUserRoleList";
import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";
import { PermissionGuard } from "src/components/PermissionGuard";
import { pageNotFoundRoute } from "../PageNotFound/PageNotFound.route";

export type AccountsManagementEditAccountParams = {
  accountId: string;
};

export const accountManagementAllowedRoles = [
  USER_ROLE.Admin,
  USER_ROLE.AccountManager,
];

export const accountsManagementEditAccountRoute =
  new AppRouteLazyBuilder<AccountsManagementEditAccountParams>({
    path: "/accounts-management/:accountId",
    lazy: async () => {
      const { AccountsManagementEditAccount } = await import(
        "./AccountsManagementEditAccount.page"
      );
      return {
        Component: function () {
          return (
            <PermissionGuard
              component={AccountsManagementEditAccount}
              allowedRoles={accountManagementAllowedRoles}
              disableMobile
              redirectTo={pageNotFoundRoute.path}
              silent
            />
          );
        },
      };
    },
  });

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type MediaCenterClipPathParam = {
  eventId: string;
};

export const mediaCenterClipRoute =
  new AppRouteLazyBuilder<MediaCenterClipPathParam>({
    path: "/media-center/:eventId",
    lazy: async () => {
      const { MediaCenterClipPage } = await import("./MediaCenterClip.page");
      return { Component: MediaCenterClipPage };
    },
  });

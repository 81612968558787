import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type ReportCreateQueryParams = {
  all?: number;
} | void;

export const reportCreateRoute = new AppRouteLazyBuilder<
  void,
  ReportCreateQueryParams
>({
  path: "/reports/create",
  lazy: async () => {
    const { ReportCreatePage } = await import("./ReportCreate.page");
    return { Component: ReportCreatePage };
  },
});

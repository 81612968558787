import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PowerSearchResultClipPathParam = {
  eventId: string;
};

export const powerSearchResultClipRoute =
  new AppRouteLazyBuilder<PowerSearchResultClipPathParam>({
    path: "/power-search/result/:eventId",
    lazy: async () => {
      const { PowerSearchResultClipPage } = await import(
        "./PowerSearchResultClip.page"
      );
      return { Component: PowerSearchResultClipPage };
    },
  });

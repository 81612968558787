import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";
import { PermissionGuard } from "src/components/PermissionGuard";
import { pageNotFoundRoute } from "../PageNotFound/PageNotFound.route";
import { accountManagementAllowedRoles } from "../AccountsManagementEditAccount/AccountsManagementEditAccount.route";

type UserManagementActivityLogsParams = {
  accountId: string;
  userId: string;
};

export const accountsManagementUserActivityLogsRoute =
  new AppRouteLazyBuilder<UserManagementActivityLogsParams>({
    path: "/accounts-management/logs/:accountId/:userId",
    lazy: async () => {
      const { UserManagementActivityLogPage } = await import(
        "../UserManagementActivityLogs/UserManagementActivityLogs.page"
      );
      return {
        Component: function () {
          return (
            <PermissionGuard
              component={UserManagementActivityLogPage}
              allowedRoles={accountManagementAllowedRoles}
              disableMobile
              redirectTo={pageNotFoundRoute.path}
              silent
            />
          );
        },
      };
    },
  });

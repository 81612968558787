import { trackEvent } from "@intercom/messenger-js-sdk";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGoogleAnalytics } from "src/api/useGoogleAnalytics";
import { useHotJar } from "src/api/useHotJar";
import { useSessionContext } from "src/api/useSessionContext";
import { isExternalToolAvailable } from "src/utils/makeUserConsentPermission";

export function AppRouterRoot({
  element,
}: {
  element: JSX.Element;
}): JSX.Element {
  useGoogleAnalytics();
  useHotJar();

  const { userConsent } = useSessionContext();

  const { pathname, search } = useLocation();
  const url = search ? `${pathname}?${search}` : pathname;

  useEffect(() => {
    if (
      !isExternalToolAvailable("permitFunctional", userConsent) ||
      !window.Intercom
    )
      return;
    trackEvent("navigation", url);
  }, [url, userConsent]);

  return element;
}

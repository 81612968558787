import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicYoutubePodcastPathParams = {
  queryId: string;
  eventId: string;
};

export const publicYoutubePodcastEventRoute =
  new AppRouteLazyBuilder<PublicYoutubePodcastPathParams>({
    path: "/public/query/:queryId/:eventId",
    lazy: async () => {
      const { PublicYoutubePodcastEventPage } = await import(
        "./PublicYoutubePodcastEvent.page"
      );
      return { Component: PublicYoutubePodcastEventPage };
    },
  });

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type AnalyticsLiteRouteQueryParams = {
  queryIds: string[];
  startDateTime: string;
  endDateTime: string;
};

export const analyticsLiteRoute = new AppRouteLazyBuilder<
  void,
  AnalyticsLiteRouteQueryParams
>({
  path: "/analytics/lite",
  lazy: async () => {
    const { AnalyticsLiteIndex } = await import("./AnalyticsLite.index");
    return { Component: AnalyticsLiteIndex };
  },
});

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";
import { PermissionGuard } from "src/components/PermissionGuard";
import { USER_ROLE } from "src/utils/useUserRoleList";
import { pageNotFoundRoute } from "../PageNotFound/PageNotFound.route";

export const userManagementRoute = new AppRouteLazyBuilder({
  path: "/user-management",
  lazy: async () => {
    const { UserManagementPage } = await import("./UserManagement.page");
    return {
      Component: function () {
        return (
          <PermissionGuard
            component={UserManagementPage}
            allowedRoles={[
              USER_ROLE.Admin,
              USER_ROLE.AccountManager,
              USER_ROLE.UserManager,
            ]}
            disableMobile
            redirectTo={pageNotFoundRoute.path}
            silent
          />
        );
      },
    };
  },
});

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicShareEventsPathParams = {
  reportId: string;
};

export const publicShareEventsRoute =
  new AppRouteLazyBuilder<PublicShareEventsPathParams>({
    path: "/public/share/:reportId",
    lazy: async () => {
      const { PublicShareEventsPage } = await import(
        "./PublicShareEvents.page"
      );
      return { Component: PublicShareEventsPage };
    },
  });

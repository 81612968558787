import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicReportPathParams = {
  reportId: string;
};

export const publicReportRoute =
  new AppRouteLazyBuilder<PublicReportPathParams>({
    path: "/public/report/:reportId",
    lazy: async () => {
      const { PublicReportPage } = await import("./PublicReport.page");
      return { Component: PublicReportPage };
    },
  });

import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SuccessImage from "./Vector.jpg";

export type EventType = "one" | "allAssociatedWith" | "all";

function makeSectionLabel(type: EventType, queryTitle: string) {
  switch (type) {
    case "one":
      return `You have successfully muted this Alert for the “${queryTitle}”`;
    case "allAssociatedWith":
      return `You have successfully muted all Alerts associated with “${queryTitle}”`;
    case "all":
      return `You have successfully muted all Alerts`;
  }
}

export function UnsubscribeAlertSuccessSection({
  onBack,
  queryTitle,
  type,
}: {
  onBack: () => void;
  queryTitle: string;
  type: EventType;
}) {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  return (
    <Stack
      alignItems="center"
      maxWidth={414}
      mx={isMobile ? 2 : undefined}
      gap={2}
      mt={5}
    >
      <Box component="img" alt="SuccessImage" src={SuccessImage} width={94} />
      <Typography variant="body1" textAlign="center">
        {makeSectionLabel(type, queryTitle)}
      </Typography>
      <Button component={Link} fullWidth variant="contained" to="/">
        Home page
      </Button>
      <Button fullWidth variant="outlined" onClick={onBack}>
        Back
      </Button>
    </Stack>
  );
}

import { useEffect, useState } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { AppRouteBuilder } from "src/models/AppRoute";
import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

/**
 * Invoke callback when user leaves route group
 */
export function useRouteGroupLeave({
  routes,
  callback,
}: {
  routes: (AppRouteBuilder<any, any> | AppRouteLazyBuilder<any, any>)[];
  callback: () => void;
}) {
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState("");

  useEffect(() => {
    if (pathname) {
      setPrevPathname(pathname);
    }
  }, [pathname]);

  useEffect(() => {
    const prevMatches = matchRoutes(routes, prevPathname);
    const nextMatches = matchRoutes(routes, pathname);

    if (prevMatches && !nextMatches) {
      return callback;
    }
  }, [prevPathname, pathname, routes, callback]);
}

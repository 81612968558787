import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";
import { ReportEditPageTabMode } from "./ReportEdit.model";

export type ReportEditPathParam = {
  reportId: string;
};

type ReportEditQueryParams = {
  pageTab?: ReportEditPageTabMode;
};

export const reportEditRoute = new AppRouteLazyBuilder<
  ReportEditPathParam,
  ReportEditQueryParams
>({
  path: "/reports/:reportId",
  lazy: async () => {
    const { ReportEditPage } = await import("./ReportEdit.page");
    return { Component: ReportEditPage };
  },
});

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicViewByQueryPathParams = {
  queryId: string;
  eventId: string;
};

export const publicViewByQueryRoute =
  new AppRouteLazyBuilder<PublicViewByQueryPathParams>({
    path: "/public/view/:queryId/:eventId",
    lazy: async () => {
      const { PublicViewQueryPage: PublicViewPage } = await import(
        "./PublicViewByQuery.page"
      );
      return { Component: PublicViewPage };
    },
  });

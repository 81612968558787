import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { common } from "@mui/material/colors";

import {
  createTheme,
  PaletteMode,
  PaletteOptions,
  ThemeProvider,
  useTheme,
  autocompleteClasses,
  outlinedInputClasses,
  alpha,
  svgIconClasses,
  colors,
  touchRippleClasses,
  inputBaseClasses,
  inputLabelClasses,
} from "@mui/material";

declare module "@mui/material/styles" {
  interface TypeBackground {
    brand: string;
  }
}

interface DrawerState {
  width: number;
  transition: Partial<{
    duration: number | string;
    easing: string;
    delay: number | string;
  }>;
}

interface DrawerMixin {
  collapsed: DrawerState;
  expanded: DrawerState;
}

declare module "@mui/material/styles" {
  interface Mixins {
    drawer: DrawerMixin;
  }
  interface MixinsOptions {
    drawer: DrawerMixin;
  }
}

interface AppThemeSwitchContextValue {
  mode: PaletteMode;

  setPaletteMode(nextMode: PaletteMode): void;
}

const storageKey = "palette-mode";
const getInitialPaletteMode = (prefersDarkMode: boolean): PaletteMode => {
  const value = localStorage.getItem(storageKey);

  switch (value) {
    case "light":
      return "light";
    case "dark":
      return "dark";
    default:
      if (value) {
        // clear invalid value out of storage
        localStorage.removeItem(storageKey);
      }

      return prefersDarkMode ? "dark" : "light";
  }
};

const AppThemeSwitchContext = React.createContext<AppThemeSwitchContextValue>({
  mode: "light",
  setPaletteMode: (_nextPaletteMode: PaletteMode) => {},
});

export function AppThemeProvider(props: PropsWithChildren<{}>) {
  const { transitions } = useTheme();
  // if you want start from system theme use this:
  // useMediaQuery("(prefers-color-scheme: dark)");
  const prefersDarkMode = false;
  const initialPaletteMode = getInitialPaletteMode(prefersDarkMode);
  const [mode, setMode] = React.useState<PaletteMode>(initialPaletteMode);

  const setPaletteModeWrapper = useCallback((nextMode: PaletteMode) => {
    setMode(nextMode);
    localStorage.setItem(storageKey, nextMode);
  }, []);

  const appThemeSwitchContext: AppThemeSwitchContextValue = React.useMemo(
    () => ({
      mode,
      setPaletteMode: setPaletteModeWrapper,
    }),
    [mode, setPaletteModeWrapper]
  );

  const themeFontFamily = "montserrat";

  const paletteLight = useMemo(() => {
    const primary: PaletteOptions["primary"] = {
      main: "rgb(31, 108, 179)",
      dark: "rgb(24, 86, 143)",
      light: "rgb(25, 118, 210)",
      contrastText: "rgb(255, 255, 255)",
    };

    const secondary: PaletteOptions["secondary"] = {
      main: "rgb(119, 169, 72)",
      dark: "rgb(82, 118, 49)",
      light: "rgb(148, 208, 91)",
      contrastText: "rgb(255, 255, 255)",
    };

    const action: PaletteOptions["action"] = {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.08)",
      selected: "rgba(0, 0, 0, 0.04)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      focus: "rgba(0, 0, 0, 0.12)",
    };

    const error: PaletteOptions["error"] = {
      main: "rgb(211, 47, 47)",
      dark: "rgb(198, 40, 40)",
      light: "rgb(239, 83, 80)",
    };

    const warning: PaletteOptions["warning"] = {
      main: "rgb(237, 108, 2)",
      dark: "rgb(230, 81, 0)",
      light: "rgb(255, 152, 0)",
      contrastText: "rgb(255, 255, 255)",
    };

    const info: PaletteOptions["info"] = {
      main: "rgba(2, 136, 209)",
      dark: "rgba(1, 87, 155)",
      light: "rgb(3, 169, 244)",
      contrastText: "rgb(255, 255, 255)",
    };

    const success: PaletteOptions["success"] = {
      main: "rgb(46, 125, 50)",
      dark: "rgb(27, 94, 32)",
      light: "rgb(76, 175, 80)",
      contrastText: "rgb(255, 255, 255)",
    };

    const background: PaletteOptions["background"] = {
      default: "rgb(242, 245, 248)",
      paper: "rgb(255, 255, 255)",
      brand: "rgb(5, 36, 65)",
    };

    const text: PaletteOptions["text"] = {
      primary: "rgb(4, 36, 65)",
      secondary: "rgba(4, 36, 65, 0.65)",
      disabled: "rgba(4, 36, 65, 0.38)",
    };

    return {
      mode: "light" as const,
      primary,
      secondary,
      action,
      error,
      warning,
      info,
      success,
      background,
      text,
      divider: "rgba(0, 0, 0, 0.12)",
    };
  }, []);

  const paletteDark = useMemo(() => {
    const primary: PaletteOptions["primary"] = {
      main: "rgba(84, 155, 234, 1)",
      dark: "rgba(24, 95, 161, 1)",
      light: "rgb(44, 124, 198)",
      contrastText: "rgba(4, 36, 65, 0.87)",
    };

    const secondary: PaletteOptions["secondary"] = {
      main: "rgb(119, 169, 72)",
      dark: "rgb(82, 118, 49)",
      light: "rgb(148, 208, 91)",
      contrastText: "rgba(4, 36, 65, 0.87)",
    };

    const error: PaletteOptions["error"] = {
      main: "rgb(244, 67, 54)",
      dark: "rgb(211, 47, 47)",
      light: "rgb(229, 115, 115)",
    };

    const warning: PaletteOptions["warning"] = {
      main: "rgb(255, 167, 38)",
      dark: "rgba(245, 124, 0)",
      light: "rgb(255, 183, 77)",
      contrastText: "rgba(0, 0, 0, 0.87)",
    };

    const info: PaletteOptions["info"] = {
      main: "rgb(67, 139, 205)",
      dark: "rgb(67, 139, 205)",
      light: "rgb(79, 195, 247)",
      contrastText: "rgba(0, 0, 0, 0.87)",
    };

    const success: PaletteOptions["success"] = {
      main: "rgb(102, 187, 106)",
      dark: "rgb(56, 142, 60)",
      light: "rgb(129, 199, 132)",
      contrastText: "rgba(0, 0, 0, 0.87)",
    };

    const background: Required<PaletteOptions["background"]> = {
      default: "rgb(18, 18, 18)",
      paper: "rgba(35, 35, 35, 1)",
      brand: "rgb(5, 36, 65)",
    };

    const text: PaletteOptions["text"] = {
      primary: "rgb(255, 255, 255)",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
    };

    const action: PaletteOptions["action"] = {
      active: "rgba(255, 255, 255, 0.56)",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "rgba(255, 255, 255, 0.16)",
      disabled: "rgba(255, 255, 255, 0.3)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
      focus: "rgba(255, 255, 255, 0.12)",
    };

    return {
      mode: "dark" as const,
      primary,
      secondary,
      action,
      error,
      warning,
      info,
      success,
      background,
      text,
      divider: "rgba(255, 255, 255, 0.12)",
    };
  }, []);

  const appTheme = React.useMemo(() => {
    const drawerMixin = {
      collapsed: {
        // collapsed state parameters
        width: 64,
        transition: {
          duration: 250,
        },
      },
      expanded: {
        // expanded state parameters
        width: 256,
        transition: {
          duration: 200,
        },
      },
    };

    const buttonHoverColor =
      mode === "light"
        ? alpha(paletteLight.primary.main, 0.04)
        : alpha(paletteDark.primary.main, 0.08);

    const buttonSelectedColor =
      mode === "light"
        ? alpha(paletteLight.primary.main, 0.12)
        : alpha(paletteDark.primary.main, 0.16);

    const touchRipple =
      mode === "light" ? alpha(common.white, 0.3) : alpha(common.black, 0.3);

    return createTheme({
      components: {
        MuiAppBar: {
          defaultProps: {
            elevation: 0,
            color: "default",
          },
          styleOverrides: {
            root: ({ theme }) => ({
              backgroundColor: theme.palette.background.default,
            }),
          },
        },
        MuiAlert: {
          styleOverrides: {
            root: ({ theme, ownerState }) => ({
              backgroundColor: (() => {
                if (ownerState.severity === "info") {
                  return theme.palette.mode === "dark"
                    ? alpha(theme.palette.info.main, 0.1)
                    : "";
                }

                if (ownerState.severity === "success") {
                  return theme.palette.mode === "dark"
                    ? theme.palette.success.main
                    : "";
                }
              })(),
              color: (() => {
                if (theme.palette.mode === "dark") {
                  return theme.palette.success.contrastText;
                }
              })(),
            }),
          },
        },
        MuiFab: {
          styleOverrides: {
            sizeSmall: {
              height: 32,
              width: 32,
            },
            sizeMedium: {
              height: 48,
              width: 48,
            },
          },
        },

        MuiChip: {
          styleOverrides: {
            // Default
            root: ({ theme, ownerState }) => ({
              fontFamily: themeFontFamily,
              fontSize: "13px",
              lineHeight: "18px",
              letterSpacing: 0.16,
              backgroundColor: (() => {
                if (ownerState.variant === "filled") {
                  return theme.palette.mode === "light"
                    ? alpha(theme.palette.action.selected, 0.08)
                    : alpha(theme.palette.action.selected, 0.16);
                }
              })(),
              ":hover": {
                backgroundColor: (() => {
                  if (ownerState.variant === "filled") {
                    return theme.palette.mode === "light"
                      ? alpha(theme.palette.action.selected, 0.12)
                      : alpha(theme.palette.action.selected, 0.12);
                  }

                  if (ownerState.variant === "outlined") {
                    return theme.palette.mode === "light"
                      ? alpha(theme.palette.action.hover, 0.04)
                      : alpha(theme.palette.action.selected, 0.08);
                  }
                })(),
              },
              ".Mui-selected": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? alpha(theme.palette.common.white, 0.23)
                    : alpha(theme.palette.common.black, 0.12),
              },
            }),

            // Avatar
            avatar: ({ theme, ownerState }) => ({
              color: (() => {
                switch (ownerState.color) {
                  case "default":
                    return theme.palette.mode === "light"
                      ? colors.grey[700]
                      : colors.grey[700];
                  case "primary":
                    return theme.palette.mode === "light"
                      ? theme.palette.primary.contrastText
                      : alpha(theme.palette.primary.contrastText, 0.87);
                  case "secondary":
                    return theme.palette.mode === "light"
                      ? theme.palette.primary.contrastText
                      : alpha(theme.palette.primary.contrastText, 0.87);
                  case "error":
                    return theme.palette.mode === "light"
                      ? theme.palette.error.contrastText
                      : theme.palette.error.contrastText;
                  case "warning":
                    return theme.palette.mode === "light"
                      ? theme.palette.warning.contrastText
                      : alpha(theme.palette.warning.contrastText, 0.87);
                  case "info":
                    return theme.palette.mode === "light"
                      ? theme.palette.info.contrastText
                      : alpha(theme.palette.info.contrastText, 0.87);
                  case "success":
                    return theme.palette.mode === "light"
                      ? theme.palette.success.contrastText
                      : alpha(theme.palette.success.contrastText, 0.87);
                }
              })(),
              backgroundColor: (() => {
                switch (ownerState.color) {
                  case "default":
                    return theme.palette.mode === "light"
                      ? alpha(colors.grey[400], 0.7)
                      : alpha(theme.palette.primary.contrastText, 0.7);
                  case "primary":
                    return theme.palette.mode === "light"
                      ? theme.palette.primary.dark
                      : theme.palette.primary.dark;
                  case "secondary":
                    return theme.palette.mode === "light"
                      ? theme.palette.secondary.dark
                      : theme.palette.secondary.dark;
                  case "error":
                    return theme.palette.mode === "light"
                      ? theme.palette.error.dark
                      : theme.palette.error.dark;
                  case "warning":
                    return theme.palette.mode === "light"
                      ? theme.palette.warning.dark
                      : theme.palette.warning.dark;
                  case "info":
                    return theme.palette.mode === "light"
                      ? theme.palette.info.dark
                      : theme.palette.info.dark;
                  case "success":
                    return theme.palette.mode === "light"
                      ? theme.palette.success.dark
                      : theme.palette.success.dark;
                }
              })(),
            }),
            // Avatar

            // DeleteIcon
            deleteIcon: ({ theme, ownerState }) => ({
              color: (() => {
                if (ownerState.variant === "filled") {
                  switch (ownerState.color) {
                    case "default":
                      return theme.palette.mode === "light"
                        ? alpha(theme.palette.common.black, 0.26)
                        : alpha(theme.palette.common.white, 0.26);
                    default:
                      return theme.palette.mode === "light"
                        ? alpha(theme.palette.common.white, 0.7)
                        : alpha(theme.palette.common.black, 0.7);
                  }
                }
              })(),
            }),
            // Default

            // Primary
            colorPrimary: ({ theme, ownerState }) => ({
              backgroundColor: (() => {
                if (ownerState.variant === "filled") {
                  return theme.palette.mode === "light"
                    ? alpha(theme.palette.primary.main, 1)
                    : alpha(theme.palette.primary.main, 1);
                }
              })(),
              "&:hover": {
                backgroundColor: (() => {
                  if (ownerState.variant === "filled") {
                    return theme.palette.mode === "light"
                      ? alpha(theme.palette.primary.dark, 1)
                      : alpha(theme.palette.primary.dark, 1);
                  }
                })(),
              },
              "&.Mui-selected": {
                backgroundColor: (() => {
                  if (ownerState.variant === "filled") {
                    return theme.palette.mode === "light"
                      ? alpha(theme.palette.primary.dark, 1)
                      : alpha(theme.palette.primary.dark, 1);
                  }
                })(),
              },
            }),
            // Primary

            // Secondary
            colorSecondary: ({ theme, ownerState }) => ({
              backgroundColor: (() => {
                if (ownerState.variant === "filled") {
                  return theme.palette.mode === "light"
                    ? alpha(theme.palette.secondary.main, 1)
                    : alpha(theme.palette.secondary.main, 1);
                }
              })(),
              "&:hover": {
                backgroundColor: (() => {
                  if (ownerState.variant === "filled") {
                    return theme.palette.mode === "light"
                      ? alpha(theme.palette.secondary.dark, 1)
                      : alpha(theme.palette.secondary.dark, 1);
                  }
                })(),
              },
              "&.Mui-selected": {
                backgroundColor: (() => {
                  if (ownerState.variant === "filled") {
                    return theme.palette.mode === "light"
                      ? alpha(theme.palette.secondary.dark, 1)
                      : alpha(theme.palette.secondary.dark, 1);
                  }
                })(),
              },
            }),
            // Secondary

            // Error
            colorError: ({ theme, ownerState }) => ({
              backgroundColor: (() => {
                if (ownerState.variant === "filled") {
                  return theme.palette.mode === "light"
                    ? alpha(theme.palette.error.main, 1)
                    : alpha(theme.palette.error.main, 1);
                }
              })(),
              "&:hover": {
                backgroundColor: (() => {
                  if (ownerState.variant === "filled") {
                    return theme.palette.mode === "light"
                      ? alpha(theme.palette.error.dark, 1)
                      : alpha(theme.palette.error.dark, 1);
                  }
                })(),
              },
              "&.Mui-selected": {
                backgroundColor: (() => {
                  if (ownerState.variant === "filled") {
                    return theme.palette.mode === "light"
                      ? alpha(theme.palette.error.dark, 1)
                      : alpha(theme.palette.error.dark, 1);
                  }
                })(),
              },
            }),
            // Error

            // Warning
            colorWarning: ({ theme, ownerState }) => ({
              backgroundColor: (() => {
                if (ownerState.variant === "filled") {
                  return theme.palette.mode === "light"
                    ? alpha(theme.palette.warning.main, 1)
                    : alpha(theme.palette.warning.main, 1);
                }
              })(),
              "&:hover": {
                backgroundColor: (() => {
                  if (ownerState.variant === "filled") {
                    return theme.palette.mode === "light"
                      ? alpha(theme.palette.warning.dark, 1)
                      : alpha(theme.palette.warning.dark, 1);
                  }
                })(),
              },
              "&.Mui-selected": {
                backgroundColor: (() => {
                  if (ownerState.variant === "filled") {
                    return theme.palette.mode === "light"
                      ? alpha(theme.palette.warning.dark, 1)
                      : alpha(theme.palette.warning.dark, 1);
                  }
                })(),
              },
            }),
            // Warning

            // Info
            colorInfo: ({ theme, ownerState }) => ({
              backgroundColor: (() => {
                if (ownerState.variant === "filled") {
                  return theme.palette.mode === "light"
                    ? alpha(theme.palette.info.main, 1)
                    : alpha(theme.palette.info.main, 1);
                }
              })(),
              "&:hover": {
                backgroundColor: (() => {
                  if (ownerState.variant === "filled") {
                    return theme.palette.mode === "light"
                      ? alpha(theme.palette.info.dark, 1)
                      : alpha(theme.palette.info.dark, 1);
                  }
                })(),
              },
              "&.Mui-selected": {
                backgroundColor: (() => {
                  if (ownerState.variant === "filled") {
                    return theme.palette.mode === "light"
                      ? alpha(theme.palette.info.dark, 1)
                      : alpha(theme.palette.info.dark, 1);
                  }
                })(),
              },
            }),
            // Info

            // Success
            colorSuccess: ({ theme, ownerState }) => ({
              backgroundColor: (() => {
                if (ownerState.variant === "filled") {
                  return theme.palette.mode === "light"
                    ? alpha(theme.palette.success.main, 1)
                    : alpha(theme.palette.success.main, 1);
                }
              })(),
              "&:hover": {
                backgroundColor: (() => {
                  if (ownerState.variant === "filled") {
                    return theme.palette.mode === "light"
                      ? alpha(theme.palette.success.dark, 1)
                      : alpha(theme.palette.success.dark, 1);
                  }
                })(),
              },
              "&.Mui-selected": {
                backgroundColor: (() => {
                  if (ownerState.variant === "filled") {
                    return theme.palette.mode === "light"
                      ? alpha(theme.palette.success.dark, 1)
                      : alpha(theme.palette.success.dark, 1);
                  }
                })(),
              },
            }),
            // Success
          },
        },
        MuiAutocomplete: {
          defaultProps: {
            slotProps: {
              paper: {
                elevation: 8,
              },
            },
          },
          styleOverrides: {
            option: () => ({
              "&.Mui-focused": {
                backgroundColor: `${buttonHoverColor}!important`,
              },
            }),
            listbox: {
              [`& .${autocompleteClasses.option}`]: {
                minHeight: 36,
              },
            },
            root: {
              [`.${inputBaseClasses.root}.${inputBaseClasses.sizeSmall}`]: {
                minHeight: "48px",
              },
              [`.${inputLabelClasses.sizeSmall}:not(.Mui-focused)`]: {
                transform: "translate(14px, 12px) scale(1)",
              },
              [`.${inputLabelClasses.sizeSmall}[data-shrink=true]`]: {
                transform: "translate(14px, -9px) scale(.75)",
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            input: ({ theme }) => ({
              "::placeholder": {
                color: theme.palette.text.secondary,
              },
            }),
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: ({ theme }) => ({
              // Weird style overrides for dark theme requested by design team
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                borderColor:
                  mode === "light"
                    ? undefined
                    : alpha(theme.palette.common.white, 0.5),
              },
              [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                {
                  borderColor:
                    mode === "light"
                      ? undefined
                      : alpha(theme.palette.primary.main, 0.8),
                },
              [`&.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]:
                {
                  borderColor:
                    mode === "light" ? undefined : theme.palette.error.main,
                },
            }),
          },
        },
        MuiCard: {
          defaultProps: {
            elevation: 0,
          },
        },
        MuiBadge: {
          styleOverrides: {
            badge: ({ theme }) => ({
              pointerEvents: "none",
              ...theme.typography.body2,
            }),
          },
        },
        MuiPaper: {
          defaultProps: {
            elevation: mode === "light" ? 0 : 2,
          },
          styleOverrides: {
            rounded: {
              borderRadius: 8,
            },
            ...(mode === "light"
              ? {
                  outlined: {
                    boxShadow: "0, 0, 0, 1px, rgba(92, 113, 132, 0.1)",
                  },
                  elevation: {
                    background: "rgba(255, 255, 255, 1);",
                  },
                  elevation0: {
                    background: "rgba(255, 255, 255, 1);",
                  },
                  elevation1: {
                    background: "rgba(255, 255, 255, 1)",
                    boxShadow:
                      `0px 1px 3px 0px rgba(92, 113, 132, 0.12);` +
                      `0px 1px 1px 0px rgba(92, 113, 132, 0.14);` +
                      `0px 2px 1px -1px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation2: {
                    background: "rgba(255, 255, 255, 1)",
                    boxShadow:
                      `0px 1px 5px 0px rgba(92, 113, 132, 0.12);` +
                      `0px 2px 2px 0px rgba(92, 113, 132, 0.14);` +
                      `0px 3px 1px -2px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation3: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 1px 8px 0px rgba(0, 0, 0, 0.12);` +
                      `0px 3px 4px 0px rgba(92, 113, 132, 0.14);` +
                      `0px 3px 3px -2px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation4: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 1px 10px 0px rgba(92, 113, 132, 0.12);` +
                      `0px 4px 5px 0px rgba(92, 113, 132, 0.14);` +
                      `0px 2px 4px -1px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation5: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 1px 14px 0px rgba(92, 113, 132, 0.12);` +
                      `0px 5px 8px 0px rgba(92, 113, 132, 0.14);` +
                      `0px 3px 5px -1px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation6: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 1px 18px 0px rgba(92, 113, 132, 0.12);` +
                      `0px 6px 10px 0px rgba(92, 113, 132, 0.14);` +
                      `0px 3px 5px -1px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation7: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 2px 16px 1px rgba(92, 113, 132, 0.12);` +
                      `0px 7px 10px 1px rgba(92, 113, 132, 0.14);` +
                      `0px 4px 5px -2px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation8: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 3px 14px 2px rgba(92, 113, 132, 0.12);` +
                      `0px 8px 10px 1px rgba(92, 113, 132, 0.14);` +
                      `0px 5px 5px -3px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation9: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 3px 16px 2px rgba(92, 113, 132, 0.12);` +
                      `0px 9px 12px 1px rgba(92, 113, 132, 0.14);` +
                      `0px 5px 6px -3px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation10: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 4px 18px 3px rgba(92, 113, 132, 0.12);` +
                      `0px 10px 14px 1px rgba(92, 113, 132, 0.14);` +
                      `0px 6px 6px -3px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation11: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 4px 20px 3px rgba(92, 113, 132, 0.12);` +
                      `0px 11px 15px 1px rgba(92, 113, 132, 0.14);` +
                      `0px 6px 7px -4px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation12: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 5px 22px 4px rgba(92, 113, 132, 0.12);` +
                      `0px 12px 17px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 7px 8px -4px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation13: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 5px 24px 4px rgba(92, 113, 132, 0.12);` +
                      `0px 13px 19px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 7px 8px -4px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation14: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 5px 26px 4px rgba(92, 113, 132, 0.12);` +
                      `0px 14px 21px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 7px 9px -4px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation15: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 6px 28px 5px rgba(92, 113, 132, 0.12);` +
                      `0px 15px 22px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 8px 9px -5px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation16: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 6px 30px 5px rgba(92, 113, 132, 0.12);` +
                      `0px 16px 24px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 8px 10px -5px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation17: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 6px 32px 5px rgba(92, 113, 132, 0.12);` +
                      `0px 17px 26px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 8px 11px -5px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation18: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 7px 34px 6px rgba(92, 113, 132, 0.12);` +
                      `0px 18px 28px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 9px 11px -5px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation19: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 7px 36px 6px rgba(92, 113, 132, 0.12);` +
                      `0px 19px 29px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 9px 12px -6px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation20: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 8px 38px 7px rgba(92, 113, 132, 0.12);` +
                      `0px 20px 31px 3px rgba(92, 113, 132, 0.14);` +
                      `0px 10px 13px -6px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation21: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 8px 40px 7px rgba(92, 113, 132, 0.12);` +
                      `0px 21px 33px 3px rgba(92, 113, 132, 0.14);` +
                      `0px 10px 13px -6px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation22: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 8px 42px 7px rgba(92, 113, 132, 0.12);` +
                      `0px 22px 35px 3px rgba(92, 113, 132, 0.14);` +
                      `0px 10px 14px -6px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation23: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow:
                      `0px 9px 44px 8px rgba(92, 113, 132, 0.12);` +
                      `0px 23px 36px 3px rgba(92, 113, 132, 0.14);` +
                      `0px 11px 14px -7px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation24: {
                    background: "rgba(255, 255, 255, 1);",
                    boxShadow: "none",
                  },
                }
              : {
                  outlined: {
                    background: "rgba(18, 18, 18, 1);",
                    boxShadow: `0px 0px 0px 1px rgba(255, 255, 255, 0.12)`,
                  },
                  elevation: {
                    background: "rgba(18, 18, 18, 1);",
                  },
                  elevation0: {
                    background: "rgba(18, 18, 18, 1);",
                  },
                  elevation1: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%);`,

                    boxShadow:
                      `0px 1px 3px 0px rgba(92, 113, 132, 0.12);` +
                      `0px 1px 1px 0px rgba(92, 113, 132, 0.14);` +
                      `0px 2px 1px -1px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation2: {
                    background:
                      "linear-gradient(180deg, rgba(255, 255, 255, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%), #121212",

                    boxShadow:
                      `0px 5px 5px -3px rgba(0, 0, 0, 0.20),` +
                      `0px 8px 10px 1px rgba(0, 0, 0, 0.14),` +
                      ` 0px 3px 14px 2px rgba(0, 0, 0, 0.12)`,
                  },
                  elevation3: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%);`,

                    boxShadow:
                      `0px 1px 8px 0px rgba(0, 0, 0, 0.12);` +
                      `0px 3px 4px 0px rgba(92, 113, 132, 0.14);` +
                      `0px 3px 3px -2px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation4: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(255, 255, 255, 0.09) 100%);`,

                    boxShadow:
                      `0px 1px 10px 0px rgba(92, 113, 132, 0.12);` +
                      `0px 4px 5px 0px rgba(92, 113, 132, 0.14);` +
                      `0px 2px 4px -1px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation5: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%);`,

                    boxShadow:
                      `0px 1px 14px 0px rgba(92, 113, 132, 0.12);` +
                      `0px 5px 8px 0px rgba(92, 113, 132, 0.14);` +
                      `0px 3px 5px -1px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation6: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%);`,

                    boxShadow:
                      `0px 1px 18px 0px rgba(92, 113, 132, 0.12);` +
                      `0px 6px 10px 0px rgba(92, 113, 132, 0.14);` +
                      `0px 3px 5px -1px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation7: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0.11) 100%);`,

                    boxShadow:
                      `0px 2px 16px 1px rgba(92, 113, 132, 0.12);` +
                      `0px 7px 10px 1px rgba(92, 113, 132, 0.14);` +
                      `0px 4px 5px -2px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation8: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%);`,

                    boxShadow:
                      `0px 3px 14px 2px rgba(92, 113, 132, 0.12);` +
                      `0px 8px 10px 1px rgba(92, 113, 132, 0.14);` +
                      `0px 5px 5px -3px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation9: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.12) 100%);`,

                    boxShadow:
                      `0px 3px 16px 2px rgba(92, 113, 132, 0.12);` +
                      `0px 9px 12px 1px rgba(92, 113, 132, 0.14);` +
                      `0px 5px 6px -3px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation10: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.13) 100%);`,

                    boxShadow:
                      `0px 4px 18px 3px rgba(92, 113, 132, 0.12);` +
                      `0px 10px 14px 1px rgba(92, 113, 132, 0.14);` +
                      `0px 6px 6px -3px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation11: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.13) 100%);`,

                    boxShadow:
                      `0px 4px 20px 3px rgba(92, 113, 132, 0.12);` +
                      `0px 11px 15px 1px rgba(92, 113, 132, 0.14);` +
                      `0px 6px 7px -4px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation12: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.14) 100%);`,

                    boxShadow:
                      `0px 5px 22px 4px rgba(92, 113, 132, 0.12);` +
                      `0px 12px 17px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 7px 8px -4px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation13: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.14) 100%);`,

                    boxShadow:
                      `0px 5px 24px 4px rgba(92, 113, 132, 0.12);` +
                      `0px 13px 19px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 7px 8px -4px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation14: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.14) 100%);`,

                    boxShadow:
                      `0px 5px 26px 4px rgba(92, 113, 132, 0.12);` +
                      `0px 14px 21px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 7px 9px -4px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation15: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.14) 100%);`,

                    boxShadow:
                      `0px 6px 28px 5px rgba(92, 113, 132, 0.12);` +
                      `0px 15px 22px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 8px 9px -5px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation16: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%);`,

                    boxShadow:
                      `0px 6px 30px 5px rgba(92, 113, 132, 0.12);` +
                      `0px 16px 24px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 8px 10px -5px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation17: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%);`,

                    boxShadow:
                      `0px 6px 32px 5px rgba(92, 113, 132, 0.12);` +
                      `0px 17px 26px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 8px 11px -5px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation18: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%);`,

                    boxShadow:
                      `0px 7px 34px 6px rgba(92, 113, 132, 0.12);` +
                      `0px 18px 28px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 9px 11px -5px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation19: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%);`,

                    boxShadow:
                      `0px 7px 36px 6px rgba(92, 113, 132, 0.12);` +
                      `0px 19px 29px 2px rgba(92, 113, 132, 0.14);` +
                      `0px 9px 12px -6px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation20: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%);`,

                    boxShadow:
                      `0px 8px 38px 7px rgba(92, 113, 132, 0.12);` +
                      `0px 20px 31px 3px rgba(92, 113, 132, 0.14);` +
                      `0px 10px 13px -6px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation21: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%);`,

                    boxShadow:
                      `0px 8px 40px 7px rgba(92, 113, 132, 0.12);` +
                      `0px 21px 33px 3px rgba(92, 113, 132, 0.14);` +
                      `0px 10px 13px -6px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation22: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%);`,

                    boxShadow:
                      `0px 8px 42px 7px rgba(92, 113, 132, 0.12);` +
                      `0px 22px 35px 3px rgba(92, 113, 132, 0.14);` +
                      `0px 10px 14px -6px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation23: {
                    background:
                      `linear-gradient(0deg, #121212, #121212),` +
                      `linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%);`,

                    boxShadow:
                      `0px 9px 44px 8px rgba(92, 113, 132, 0.12);` +
                      `0px 23px 36px 3px rgba(92, 113, 132, 0.14);` +
                      `0px 11px 14px -7px rgba(92, 113, 132, 0.2);`,
                  },
                  elevation24: {
                    boxShadow: "none",
                  },
                }),
          },
        },
        MuiTooltip: {
          defaultProps: {
            arrow: true,
            placement: "top",
            enterDelay: 500,
            enterNextDelay: 500,
          },
          styleOverrides: {
            arrow: ({ theme }) => ({
              color:
                theme.palette.mode === "light"
                  ? "rgba(24, 86, 143, 1)"
                  : "rgba(24, 95, 161, 1)",
            }),
            tooltip: ({ theme }) => ({
              background:
                theme.palette.mode === "light"
                  ? "rgba(24, 86, 143, 1)"
                  : "rgba(24, 95, 161, 1)",
            }),
          },
        },
        MuiDrawer: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              transition: transitions.create("width", {
                duration: ownerState.open
                  ? drawerMixin.expanded.transition.duration
                  : drawerMixin.collapsed.transition.duration,
              }),
              width: ownerState.open
                ? drawerMixin.expanded.width
                : drawerMixin.collapsed.width,
            }),
            paper: {
              width: "inherit",
              overflowY: "auto",
              overflowX: "clip",
            },
            paperAnchorLeft: {
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              borderRightStyle: "none",
            },
          },
          defaultProps: {
            transitionDuration: {
              enter: drawerMixin.collapsed.transition.duration,
              exit: drawerMixin.expanded.transition.duration,
            },
          },
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
              ":hover": {
                background: buttonHoverColor,
              },
              "&.Mui-selected": {
                background: buttonSelectedColor,
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: ({ theme }) => ({
              overflow: "hidden",
              borderRadius: theme.spacing(1),
              boxShadow: "none",
              textTransform: "none",
            }),
            contained: ({ theme }) => ({
              ":hover": {
                boxShadow:
                  theme.palette.mode === "dark"
                    ? "0px 4px 5px 0px rgba(92, 113, 132, 0.14)"
                    : "inherit",
              },
            }),
          },
        },
        MuiButtonGroup: {
          styleOverrides: {
            root: {
              overflow: "hidden",
              boxShadow: "none",
            },
          },
        },
        MuiTouchRipple: {
          styleOverrides: {
            child: {
              backgroundColor: touchRipple,
            },
          },
        },
        MuiMenu: {
          defaultProps: {
            slotProps: {
              paper: {
                elevation: mode === "light" ? 8 : 2,
              },
            },
          },
          styleOverrides: {
            list: ({ theme }) => ({
              background: theme.palette.background.paper,
            }),
          },
        },
        MuiListSubheader: {
          styleOverrides: {
            root: ({ theme }) => ({
              backgroundColor: theme.palette.background.default,
              "&:hover": {
                backgroundColor: theme.palette.background.default,
              },
            }),
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              ":hover": {
                background: buttonHoverColor,
              },
              "&.Mui-selected": {
                background: buttonSelectedColor,
              },
            },
          },
        },
        MuiToggleButtonGroup: {
          styleOverrides: {
            root: ({ theme }) => ({
              borderRadius: theme.spacing(1),
              overflow: "hidden",
            }),
            grouped: ({ theme }) => ({
              "&:not(:first-of-type)": {
                borderLeftStyle: "solid",
                borderLeftWidth: 1,
                borderLeftColor: theme.palette.divider,
              },
            }),
          },
        },
        MuiToggleButton: {
          styleOverrides: {
            root: ({ theme }) => ({
              textTransform: "none",

              background: theme.palette.background.paper,

              overflow: "hidden",
              borderRadius: theme.spacing(1),
              [`.${touchRippleClasses.root}`]: {
                borderRadius: 0,
              },

              "&:hover": {
                background: buttonHoverColor,
                zIndex: 1,
              },
              "&.Mui-selected": {
                background: buttonSelectedColor,
                zIndex: 2,

                [`.${svgIconClasses.root}`]: {
                  color: theme.palette.primary.main,
                },
              },
            }),
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              "&.Mui-selected": {
                background: "transparent",
              },
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            root: {
              fontSize: 12,
              border: "none",
            },
            selectLabel: ({ theme }) => ({
              fontSize: "inherit",
              color: theme.palette.text.secondary,
            }),
            displayedRows: {
              fontSize: "inherit",
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              minHeight: 48,
              ":hover": {
                background: buttonHoverColor,
              },
              "&.Mui-selected": {
                background: buttonSelectedColor,
              },
            },
          },
        },
        MuiSlider: {
          styleOverrides: {
            valueLabel: ({ theme }) => ({
              backgroundColor: theme.palette.primary.main,
            }),
            colorPrimary: ({ theme }) => ({
              color: theme.palette.info.dark,
            }),
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: ({ theme }) => ({
              overflow: "hidden",
              background: theme.palette.background.paper,
            }),
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: ({ theme }) => ({
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: theme.spacing(3),
              paddingBottom: theme.spacing(3),
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
            }),
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              overflow: "visible",
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: ({ theme }) => ({
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: theme.spacing(3),
              paddingBottom: theme.spacing(3),
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
            }),
          },
        },
      },
      typography: {
        allVariants: {
          fontFamily: themeFontFamily,
        },
        h1: {
          fontSize: "96px",
          fontWeight: 700,
          lineHeight: "116.7%",
          letterSpacing: "-1.5px",
        },
        h2: {
          fontSize: "68px",
          fontWeight: 700,
          lineHeight: "120%",
          letterSpacing: "-0.5px",
        },
        h3: {
          fontSize: "48px",
          fontWeight: 700,
          lineHeight: "116.7%",
        },
        h4: {
          fontWeight: 700,
          fontSize: "34px",
          lineHeight: "123.5%",
          letterSpacing: "0.25px",
        },
        h5: {
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "133.4%",
        },
        h6: {
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "160%",

          letterSpacing: "0.15px",
        },
        body1: {
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "150%",
          letterSpacing: "0.15px",
        },
        body2: {
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "143%",
          letterSpacing: "0.17px",
        },
        subtitle1: {
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "150%",
          letterSpacing: "0.15px",
        },
        subtitle2: {
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "150%",
          letterSpacing: "0.1px",
        },
        overline: {
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "266%",
          letterSpacing: "1px",
          textTransform: "uppercase",
        },
        caption: {
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "166%",
          letterSpacing: " 0.4px",
        },
      },
      mixins: {
        drawer: drawerMixin,
      },
      palette: mode === "light" ? paletteLight : paletteDark,
    });
  }, [mode, transitions, paletteLight, paletteDark]);

  return (
    <AppThemeSwitchContext.Provider value={appThemeSwitchContext}>
      <ThemeProvider theme={appTheme}>{props.children}</ThemeProvider>
    </AppThemeSwitchContext.Provider>
  );
}

export const useAppThemeSwitchContext = () => useContext(AppThemeSwitchContext);

import { getUnixTime } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import Intercom, { boot, shutdown } from "@intercom/messenger-js-sdk";
import { PropsWithChildren, createContext, useEffect } from "react";
import { useSessionContext } from "src/api/useSessionContext";
import { useIntercomButtonState } from "src/api/useIntercomButtonState";
import { useIsMobile } from "src/utils/useIsMobile";
import { isExternalToolAvailable } from "src/utils/makeUserConsentPermission";

export const intercomButtonId = "custom-intercom-button";

export const intercomIntegrationsSurveyId = 43607025;

const IntercomContext = createContext<{}>({});

export function IntercomProvider({ children }: PropsWithChildren<{}>) {
  const isMobile = useIsMobile();
  const session = useSessionContext();
  const { user } = useAuth0();
  const { userId, accountId, account, userConsent } = session;
  const { isShown } = useIntercomButtonState();

  useEffect(() => {
    Intercom({
      app_id: "zpagqe1z",
      //In connection with the suggestion from point 3 of https://www.intercom.com/help/en/articles/2361922-intercom-messenger-cookies, we are adding this to the Intercom configuration.
      disabled: true,
    });
  }, []);

  useEffect(() => {
    if (!isExternalToolAvailable("permitFunctional", userConsent)) {
      shutdown();
      return;
    }

    if (user && userId) {
      boot({
        api_base: "https://api-iam.intercom.io",
        app_id: "zpagqe1z",
        disabled: false,
        custom_launcher_selector: `#${intercomButtonId}`,
        hide_default_launcher: true,
        // user data
        user_id: userId,
        name: user.name,
        email: user.email,
        created_at: getUnixTime(new Date()), // Sign-up date as a Unix timestamp
        avatar: {
          type: "avatar",
          image_url: user.picture,
        },
        company: {
          company_id: accountId,
          name: account.name,
          plan: account.status,
          size: account.seatCount,
        },
      });
    }
  }, [user, userId, accountId, account, isMobile, isShown, userConsent]);

  return (
    <IntercomContext.Provider value={IntercomContext} children={children} />
  );
}

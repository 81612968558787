import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type SnapshotsClipViewerParams = {
  stationId?: string;
  date?: string;
};

export const snapshotsClipViewerRoute =
  new AppRouteLazyBuilder<SnapshotsClipViewerParams>({
    path: "/snapshot/result/clip/:stationId/:date",
    lazy: async () => {
      const { SnapshotsClipViewer } = await import(
        "./SnapshotsClipViewer.page"
      );
      return { Component: SnapshotsClipViewer };
    },
  });

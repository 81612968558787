import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { useCallback, useState } from "react";
import { useQuery, UseQueryOptions } from "react-query";

export const apiSessionPath: keyof paths = "/api/Session";
const method: keyof paths[typeof apiSessionPath] = "get";

type Endpoint = paths[typeof apiSessionPath];
type Response = Endpoint[typeof method]["responses"];
type SessionData = Response["200"]["content"]["application/json"];

/** Fetches session data from the API */
export function useSessionQuery(
  options?: Pick<UseQueryOptions<SessionData>, "onSuccess" | "onError">
) {
  const [cacheTime, setCacheTime] = useState(0);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const fetchSession = useCallback(async (): Promise<SessionData> => {
    const token = await getAccessTokenSilently();
    const { data } = await axios<SessionData>(apiSessionPath, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const nowTimestamp = Date.now();
    const expiryDate = new Date(data.expiry);
    const expiryTimestamp = expiryDate.getTime();
    const cacheTimeRemains = expiryTimestamp - nowTimestamp;

    if (cacheTimeRemains > 0) {
      setCacheTime(expiryTimestamp);
    } else {
      setCacheTime(0);
    }

    return data;
  }, [getAccessTokenSilently]);

  return useQuery({
    ...options,
    queryKey: [apiSessionPath],
    enabled: isAuthenticated,
    cacheTime,
    retry: 3,
    refetchOnWindowFocus: "always",
    queryFn: fetchSession,
  });
}

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicMediaCenterItemPathParams = {
  userId: string;
  itemId: string;
};

export const publicMediaCenterItemRoute =
  new AppRouteLazyBuilder<PublicMediaCenterItemPathParams>({
    path: "/public/media-center/:userId/:itemId",
    lazy: async () => {
      const { PublicMediaCenterItemPage } = await import(
        "./PublicMediaCenterItem.page"
      );
      return { Component: PublicMediaCenterItemPage };
    },
  });

import "./i18n";
import "./index.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";

// This polyfill is required on iPhone as iOS did not support fullscreen API
// https://caniuse.com/mdn-api_document_exitfullscreen
import "fullscreen-api-polyfill";

import CssBaseline from "@mui/material/CssBaseline";

import React from "react";
import ReactDOM from "react-dom/client";

import { QueryClient, QueryClientProvider } from "react-query";
import { AppLocalizationProvider } from "./providers/AppLocalizationProvider";
import { AppThemeProvider } from "./providers/AppThemeProvider";
import { DebugOverlay } from "./components/DebugOverlay";
import { AppSnackbarProvider } from "./providers/AppSnackbarProvider";
import { PushNotificationProvider } from "./providers/PushNotificationProvider/PushNotificationProvider";
import { AppErrorBoundary } from "./components/AppErrorBoundary/AppErrorBoundary";
import { App } from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <CssBaseline enableColorScheme />
      <AppLocalizationProvider>
        <QueryClientProvider client={queryClient}>
          <PushNotificationProvider>
            <AppSnackbarProvider>
              <AppErrorBoundary>
                <App />
              </AppErrorBoundary>
              <DebugOverlay />
            </AppSnackbarProvider>
          </PushNotificationProvider>
        </QueryClientProvider>
      </AppLocalizationProvider>
    </AppThemeProvider>
  </React.StrictMode>
);

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export const dateTimeSearchInitialRoute = new AppRouteLazyBuilder({
  path: "/date-time-search/initial",
  lazy: async () => {
    const { DateTimeSearchInitialPage } = await import(
      "./DateTimeSearchInitial.page"
    );
    return { Component: DateTimeSearchInitialPage };
  },
});

import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Home from "@mui/icons-material/Home";
import { LogoutOutlined } from "@mui/icons-material";
import DesktopMacOutlined from "@mui/icons-material/DesktopMacOutlined";
import { useNavigate } from "react-router-dom";
import { Logo } from "src/components/logo/Logo";
import { watchListRoute } from "src/pages/WatchList/WatchList.route";
import { HeroSwirlContainer } from "src/components/HeroSwirlContainer/HeroSwirlContainer";
import { useSessionDelete } from "src/api/useSessionDelete";
import { useIsUserRegistered } from "src/api/useIsUserRegistered";

export const AppErrorPage = (): JSX.Element => {
  const { palette, breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const navigate = useNavigate();
  const { user, logout } = useAuth0();
  const sessionDelete = useSessionDelete();
  const isUserRegistered = useIsUserRegistered();

  const handleLogout = async () => {
    // wait for session deletion
    await sessionDelete.mutateAsync();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const toSiteButton = (
    <Button
      href="https://www.tveyes.com/"
      startIcon={<Home />}
      variant="contained"
      color="info"
      size="large"
      children="To Website"
      sx={{
        width: isMobile ? "100%" : "169px",
        color: palette.text.primary,
        borderRadius: "20px",
      }}
    />
  );

  const toWatchListButton = isUserRegistered && (
    <Button
      startIcon={<DesktopMacOutlined />}
      variant="outlined"
      color="primary"
      size="large"
      children="To Watchlist"
      sx={{
        width: isMobile ? "100%" : "169px",
        borderRadius: "20px",
        color: palette.common.white,
        borderColor: palette.common.white,
      }}
      onClick={() => {
        console.log(watchListRoute.path, "NAV");
        navigate(watchListRoute.path);
      }}
    />
  );

  const logoutButton = user && (
    <Button
      startIcon={<LogoutOutlined />}
      onClick={handleLogout}
      variant="contained"
      color="secondary"
      size="large"
      sx={{
        width: isMobile ? "100%" : "169px",
        borderRadius: "20px",
        color: palette.text.primary,
        borderColor: palette.common.white,
      }}
      children="Log Out"
    />
  );

  const mobileContent = (
    <HeroSwirlContainer
      direction="column"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
      }}
    >
      <Stack
        direction="column"
        width="100%"
        gap={3}
        sx={{
          alignItems: "center",
          px: 2,
          pt: 2,
        }}
      >
        <Logo dark />
        <Typography variant="h4" sx={{ color: palette.common.white }}>
          Application Error occurred
        </Typography>
        <Typography
          variant="body2"
          textAlign="center"
          width={300}
          sx={{ color: palette.common.white }}
        >
          The application encountered an error.
          <br />
          We're working to fix it.
        </Typography>
        {/* zIndex is needed on the small height of the screen when buttons are overlapped with the image */}
        <Stack direction="column" gap={2} width="100%" zIndex={1}>
          {toSiteButton}
          {toWatchListButton}
          {logoutButton}
        </Stack>
      </Stack>
    </HeroSwirlContainer>
  );

  const desktopContent = (
    <HeroSwirlContainer
      direction="row"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="space-around"
      alignSelf="center"
      pb="10%"
      px={4}
    >
      <Stack gap={4}>
        <Logo dark />
        <Typography
          variant="h3"
          fontSize={44}
          fontWeight={300}
          sx={{ color: palette.common.white }}
        >
          Application Error occurred
        </Typography>
        <Typography
          variant="body1"
          maxWidth={463}
          sx={{ color: palette.common.white }}
        >
          The application encountered an error.
          <br />
          We're working to fix it.
        </Typography>
        {/* zIndex is needed on the small height of the screen when buttons are overlapped with the image */}
        <Stack direction={{ sm: "column", md: "row" }} gap={2} zIndex={1}>
          {toSiteButton}
          {toWatchListButton}
          {logoutButton}
        </Stack>
      </Stack>
      <Typography
        sx={{
          fontSize: { sm: 140, lg: 280 },
          lineHeight: "280px",
          color: palette.primary.light,
        }}
      >
        500
      </Typography>
    </HeroSwirlContainer>
  );

  return (
    <Stack
      sx={{
        backgroundColor: palette.background.brand,
        position: "relative",
        overflow: "hidden",
        flex: 1,
      }}
    >
      {isMobile ? mobileContent : desktopContent}
    </Stack>
  );
};

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type DateTimeSearchClipPathParam = {
  // no params for now
};

export const dateTimeSearchClipRoute =
  new AppRouteLazyBuilder<DateTimeSearchClipPathParam>({
    path: "/date-time-search/results/clip",
    lazy: async () => {
      const { DateTimeSearchClipPage } = await import(
        "./DateTimeSearchClip.page"
      );
      return { Component: DateTimeSearchClipPage };
    },
  });

import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "@tveyes/twosionwebapischema";
import { enqueueSnackbar } from "notistack";
import { useInfiniteQuery } from "react-query";
import { makeApiUrl } from "src/utils/makeApiUrl";

const apiPath: keyof paths = "/api/WatchList";
const method: keyof paths[typeof apiPath] = "post";

type Endpoint = Required<paths[typeof apiPath][typeof method]>;
type Parameters = {
  query: Endpoint["parameters"]["query"];
  filters?: NonNullable<Endpoint["requestBody"]>["content"]["application/json"];
};

export type WatchQueryListResponse =
  Endpoint["responses"][200]["content"]["application/json"];

export type WatchListQueries = Required<
  NonNullable<WatchQueryListResponse["results"]>
>;

export const watchQueryListLazyKey = "watchQueryListLazyKey";

export function useWatchQueryListLazy(params: Parameters) {
  const { query, filters } = params;
  const { getAccessTokenSilently } = useAuth0();

  return useInfiniteQuery<WatchQueryListResponse, unknown>({
    queryKey: [watchQueryListLazyKey, filters],
    keepPreviousData: true,
    queryFn: async ({ pageParam }) => {
      const token = await getAccessTokenSilently();
      console.log(`@@ DEBUG:queryFn`, { pageParam, query });
      const url = makeApiUrl<Endpoint["parameters"]>(apiPath, {
        query: {
          groupByCluster: false,
          ...query,
          ...pageParam,
        },
      });

      const { data } = await axios<WatchQueryListResponse>(url, {
        data: filters,
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return data;
    },
    onError: (error) => {
      enqueueSnackbar(`${error}`, { variant: "error" });
    },
  });
}

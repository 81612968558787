import { useState } from "react";

export function useOpenState(initialState?: boolean) {
  const [isOpen, setIsOpen] = useState(initialState ?? false);
  const show = () => setIsOpen(true);
  const hide = () => setIsOpen(false);

  return {
    isOpen,
    show,
    hide,
  };
}

import axios from "axios";
import { useTranslation } from "react-i18next";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { paths } from "@tveyes/twosionwebapischema";
import { makeApiUrl } from "src/utils/makeApiUrl";
import { watchQueryListKey } from "./useWatchQueryList";
import { watchQueryListLazyKey } from "./useWatchQueryListLazy";
import { watchQueryKey } from "./useWatchQuery";

const apiPath: keyof paths = "/api/user/{userId}/query/{queryId}/unsubscribe";
const method: keyof paths[typeof apiPath] = "post";

type Endpoint = paths[typeof apiPath][typeof method];
type Response = Endpoint["responses"]["200"]["content"]["application/json"];

type RequestBody = NonNullable<
  Endpoint["requestBody"]
>["content"]["application/json"];

type RequestMutationFnParams = {
  params: Endpoint["parameters"];
  body: RequestBody;
};

export function useUnsubscribeAllAssociatedWith(
  options?: Omit<
    UseMutationOptions<Response, unknown, RequestMutationFnParams, void>,
    "mutationFn"
  > & {
    origin?: "watchlist" | "editPage";
  }
) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation<Response, unknown, RequestMutationFnParams, void>({
    ...options,
    mutationKey: [apiPath],
    mutationFn: async ({
      body,
      params,
    }: RequestMutationFnParams): Promise<Response> => {
      const url = makeApiUrl<Endpoint["parameters"]>(apiPath, params);
      const { data } = await axios<Response>(url, {
        method,
        data: body,
      });

      return data;
    },
    onSuccess(data, variables, context) {
      if (options?.origin) {
        enqueueSnackbar(
          t(
            "alertUnsubscribeAllAssociatedWithSuccess",
            "You have unsubscribed"
          )
        );
      }

      options?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      enqueueSnackbar(`${error}`, {
        variant: "error",
      });

      options?.onError?.(error, variables, context);
    },
    onSettled(data, error, variables, context) {
      if (!options?.origin) return;

      if (options.origin === "watchlist") {
        queryClient.refetchQueries({
          queryKey: watchQueryListKey,
        });
        queryClient.refetchQueries({
          queryKey: watchQueryListLazyKey,
        });
      }

      if (options.origin === "editPage") {
        queryClient.refetchQueries({
          queryKey: watchQueryKey,
        });
      }

      options?.onSettled && options.onSettled(data, error, variables, context);
    },
  });
}

import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useProfileImage } from "src/api/useProfileImage";

const avatarIconSize = 24;

export function LoginLogoutButton({ color }: { color?: string | null }) {
  const { palette, breakpoints } = useTheme();
  const { user, loginWithRedirect } = useAuth0();
  const { data } = useProfileImage();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const handleLogin = () => {
    loginWithRedirect({
      appState: {
        returnTo: `${window.location.pathname}?${window.location.search}`,
      },
    });
  };

  const avatar = (
    <Avatar
      sx={{
        width: avatarIconSize,
        height: avatarIconSize,
        color: palette.background.brand,
        backgroundColor: "white",
      }}
      src={data?.image}
      alt="Avatar icon"
    />
  );

  if (user) {
    const fullName = `${user.given_name}`;
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        {avatar}
        <Typography color={palette.getContrastText(palette.background.brand)}>
          {fullName}
        </Typography>
      </Stack>
    );
  }

  if (isMobile) {
    return (
      <IconButton onClick={handleLogin} sx={{ pl: 0, pr: 0 }}>
        {avatar}
      </IconButton>
    );
  }

  return (
    <Button
      children="Log In"
      startIcon={avatar}
      onClick={handleLogin}
      sx={{ color: color || "inherit" }}
    />
  );
}

import {
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useTheme,
  styled,
  Palette,
  Stack,
} from "@mui/material";
import { TooltipBaseProps } from "./TooltipBase.model";

type StyledTooltipProps = TooltipProps &
  TooltipBaseProps & {
    palette: Palette;
  };

const StyledTooltip = styled(({ className, ...props }: StyledTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ palette, width, maxWidth, textAlign }: any) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.primary.dark,
    borderRadius: "4px",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "14px",
    padding: "4px 8px",
    textAlign: textAlign || "left",
    fontFamily: "montserrat",
    ...(width ? { width } : {}),
    ...(maxWidth ? { maxWidth } : {}),
  },
  [`& .${tooltipClasses.arrow}:before`]: {
    backgroundColor: palette.primary.dark,
  },
}));

export const TooltipBase = ({
  children,
  width,
  maxWidth,
  textAlign,
  ...props
}: TooltipBaseProps) => {
  const { palette } = useTheme();

  return (
    <StyledTooltip
      {...props}
      palette={palette}
      width={width}
      maxWidth={maxWidth}
      textAlign={textAlign}
    >
      <Stack sx={{ width: "unset" }}>{children}</Stack>
    </StyledTooltip>
  );
};

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";
import { PermissionGuard } from "src/components/PermissionGuard";
import { USER_ROLE } from "src/utils/useUserRoleList";

export const userManagementCreateRoute = new AppRouteLazyBuilder({
  path: "/user-management/create",
  lazy: async () => {
    const { UserManagementCreatePage } = await import(
      "./UserManagementCreate.page"
    );
    return {
      Component: function () {
        return (
          <PermissionGuard
            component={UserManagementCreatePage}
            allowedRoles={[
              USER_ROLE.Admin,
              USER_ROLE.AccountManager,
              USER_ROLE.UserManager,
            ]}
            disableMobile
            redirectTo="/"
          />
        );
      },
    };
  },
});

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type SnapshotsClipEditorPathParam = {
  // no params for now
};

export const snapshotsClipEditorRoute =
  new AppRouteLazyBuilder<SnapshotsClipEditorPathParam>({
    path: "/snapshot/result/edit",
    lazy: async () => {
      const { SnapshotsClipEditorPage } = await import(
        "./SnapshotsClipEditor.page"
      );
      return { Component: SnapshotsClipEditorPage };
    },
  });

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { PopperBase } from "../PopperBase/PopperBase";

export type DrawerNavMenuItemProps = {
  icon: JSX.Element;
  label: string;
  tooltip?: string;
  path: string;
  secondaryIcon?: JSX.Element;
  onClick?: () => void;
  popperProps?: {
    isOpen: boolean;
    hide: () => void;
    content: JSX.Element;
  };
};

// This is to extract first path segment for a active root route detection:
// i.e. "/date-time-search" from "/date-time-search/initial"
const rootRegEx = /\/[\w\-\s]+/;

export const DrawerNavMenuItem = ({
  label,
  icon,
  tooltip,
  path,
  secondaryIcon,
  popperProps,
  onClick,
}: DrawerNavMenuItemProps) => {
  const { pathname } = useLocation();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));

  const menuItemRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLLIElement | null>(null);

  useEffect(() => {
    setAnchorEl(menuItemRef.current);
  }, [menuItemRef]);

  const isExternalLink = path.startsWith("https://");

  const [pathRoot] = rootRegEx.exec(path) || [];
  const selected = !!pathRoot && pathname.startsWith(pathRoot);

  const item = (
    <ListItem disablePadding disableGutters sx={{ height: 48 }}>
      <ListItemButton
        disableGutters
        selected={selected}
        sx={{ borderRadius: 2, padding: 1, minWidth: 48 }}
        component={Link}
        to={path}
        target={isExternalLink ? "_blank" : undefined}
        onClick={onClick}
      >
        <ListItemIcon
          children={icon}
          sx={{
            minWidth: 32,
            marginRight: 2,
            justifyContent: "center",
            color: selected ? "primary.main" : "text.secondary",
          }}
        />
        <ListItemText
          primary={label}
          sx={{
            "& span": {
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            },
          }}
        />
        {secondaryIcon && (
          <ListItemIcon
            children={secondaryIcon}
            sx={{
              minWidth: 20,
              justifyContent: "center",
              color: selected ? "primary.main" : "text.secondary",
            }}
          />
        )}
      </ListItemButton>
    </ListItem>
  );

  const navMenuItem = popperProps ? (
    <Stack position="relative" ref={menuItemRef}>
      {item}
      <PopperBase
        open={popperProps?.isOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          popperProps.hide();
        }}
        placement={isMobile ? "right-start" : "right"}
        children={popperProps.content}
        sx={{
          width: isMobile ? 288 : 358,
          ml: isMobile ? "65px !important" : "24px !important",
          top: isMobile ? "-155px !important" : "unset",
        }}
        arrowSx={{
          top: "9px !important",
          ml: "-12px",
        }}
      />
    </Stack>
  ) : (
    item
  );

  return (
    <TooltipBase placement="right" title={tooltip} children={navMenuItem} />
  );
};

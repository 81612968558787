import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";
import { PermissionGuard } from "src/components/PermissionGuard";
import { accountManagementAllowedRoles } from "../AccountsManagementEditAccount/AccountsManagementEditAccount.route";
import { pageNotFoundRoute } from "../PageNotFound/PageNotFound.route";

export const accountsManagementCreateAccountRoute = new AppRouteLazyBuilder({
  path: "/accounts-management/create-account",
  lazy: async () => {
    const { AccountsManagementCreateAccount } = await import(
      "./AccountsManagementCreateAccount.page"
    );
    return {
      Component: function () {
        return (
          <PermissionGuard
            component={AccountsManagementCreateAccount}
            allowedRoles={accountManagementAllowedRoles}
            disableMobile
            redirectTo={pageNotFoundRoute.path}
            silent
          />
        );
      },
    };
  },
});

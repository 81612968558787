import { Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";

export function AppErrorBoundary({ children }: PropsWithChildren) {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => {
        return (
          <Stack height="100%" justifyContent="center">
            <Typography variant="h5" color="error" align="center">
              An error occurred
            </Typography>
          </Stack>
        );
      }}
      onError={(info) => {
        console.log(info);
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

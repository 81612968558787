import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicReportPDFPathParams = {
  reportId: string;
  eventId: string;
};

export const publicReportPDFRoute =
  new AppRouteLazyBuilder<PublicReportPDFPathParams>({
    path: "/public/reportPDF/:reportId",
    lazy: async () => {
      const { PublicReportPDFPage } = await import("./PublicReportPDF.page");
      return { Component: PublicReportPDFPage };
    },
  });

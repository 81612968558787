import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicReportClipViewerPathParams = {
  reportId: string;
  eventId: string;
};

export const publicReportClipViewerRoute =
  new AppRouteLazyBuilder<PublicReportClipViewerPathParams>({
    path: "/public/report/:reportId/:eventId/clip",
    lazy: async () => {
      const { PublicReportClipViewerPage } = await import(
        "./PublicReportClipViewer.page"
      );
      return { Component: PublicReportClipViewerPage };
    },
  });

import { FaceRetouchingOff } from "@mui/icons-material";
import {
  Fab,
  IconButtonProps,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TooltipBase } from "src/components/TooltipBase/TooltipBase";
import { stopImpersonateUserFABClasses } from "./StopImpersonateUserFAB.const";

export type StopImpersonateUserFABProps = Omit<IconButtonProps, "children">;

export function StopImpersonateUserFAB({
  ...props
}: StopImpersonateUserFABProps) {
  const { palette, breakpoints, zIndex } = useTheme();
  const downXl = useMediaQuery(breakpoints.down("xl"));

  return (
    <Stack
      className={`${downXl ? stopImpersonateUserFABClasses.hideButton : ""}`}
      sx={{
        position: "fixed",
        bottom: "88px",
        right: downXl ? "-26px" : "28px",
        transition: "transform 500ms",
        zIndex: zIndex.tooltip,
        [`&.${stopImpersonateUserFABClasses.hideButton}:hover`]: {
          transform: "translateX(-38px)",
          transition: "transform 500ms",
        },
      }}
    >
      <TooltipBase title="Exit impersonation">
        <Fab
          size="medium"
          sx={{
            background: palette.error.main,
            color: "#FFF",
            "&:hover": {
              filter: "brightness(85%)",
              background: palette.error.main,
            },
          }}
          {...props}
          children={<FaceRetouchingOff />}
        />
      </TooltipBase>
    </Stack>
  );
}

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isExternalToolAvailable } from "src/utils/makeUserConsentPermission";
import { useSessionContext } from "./useSessionContext";

declare global {
  interface Window {
    gtag?: (
      name: string,
      action: string,
      params: { page_path: string }
    ) => void;
    dataLayer: Record<string, any>[];
  }
}

export function useGoogleAnalytics() {
  const location = useLocation();

  const { userConsent } = useSessionContext();

  useEffect(() => {
    if (!isExternalToolAvailable("permitAnalytics", userConsent)) return;

    // Create Google Analytics script tag dynamically
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-SCECYDWRQW";

    document.head.appendChild(script);

    // Initialize GA
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any) {
      window.dataLayer.push(args);
    }

    gtag("js", new Date());
    gtag("config", "G-SCECYDWRQW", { anonymize_ip: true });

    return () => {
      document.head.removeChild(script); // Cleanup if necessary
    };
  }, [userConsent]);

  useEffect(() => {
    // track pageview with gtag / react-ga / react-ga4, for example:
    if (window.gtag) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);
}

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type WatchListUpdatePathParam = {
  queryId: string;
};

export const watchListUpdateRoute =
  new AppRouteLazyBuilder<WatchListUpdatePathParam>({
    path: "/watchlist/update/:queryId",
    lazy: async () => {
      const { WatchListUpdatePage } = await import("./WatchListUpdate.page");
      return { Component: WatchListUpdatePage };
    },
  });

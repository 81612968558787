import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicViewBySearchPathParams = {
  eventId: string;
};

export const publicViewBySearchRoute =
  new AppRouteLazyBuilder<PublicViewBySearchPathParams>({
    path: "/public/view/search/:eventId",
    lazy: async () => {
      const { PublicViewBySearchPage: PublicViewPage } = await import(
        "./PublicViewBySearch.page"
      );
      return { Component: PublicViewPage };
    },
  });

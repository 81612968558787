import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicSharedEventPathParams = {
  reportId: string;
  eventId: string;
};

export const publicSharedEventRoute =
  new AppRouteLazyBuilder<PublicSharedEventPathParams>({
    path: "/public/share/:reportId/:eventId",
    lazy: async () => {
      const { PublicSharedEventPage } = await import(
        "./PublicSharedEvent.page"
      );
      return { Component: PublicSharedEventPage };
    },
  });

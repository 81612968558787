import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type WatchListTermResultClipPathParam = {
  queryId: string;
  eventId: string;
};

export const watchListTermResultClipRoute =
  new AppRouteLazyBuilder<WatchListTermResultClipPathParam>({
    path: "/watchlist/:queryId/:eventId",
    lazy: async () => {
      const { WatchListTermResultClipPage } = await import(
        "./WatchListTermResultClip.page"
      );
      return { Component: WatchListTermResultClipPage };
    },
  });

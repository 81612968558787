import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type PublicUserInvitePathParams = {
  inviteId: string;
};

export const publicUserInviteRoute =
  new AppRouteLazyBuilder<PublicUserInvitePathParams>({
    path: "/public/invite/:inviteId",
    lazy: async () => {
      const { PublicUserInvitePage } = await import("./PublicUserInvite.page");
      return { Component: PublicUserInvitePage };
    },
  });

import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

export type DateTimeSearchClipEditorPathParam = {
  // no params for now
};

export const dateTimeSearchClipEditorRoute =
  new AppRouteLazyBuilder<DateTimeSearchClipEditorPathParam>({
    path: "/date-time-search/results/edit",
    lazy: async () => {
      const { DateTimeSearchClipEditorPage } = await import(
        "./DateTimeSearchClipEditor.page"
      );
      return { Component: DateTimeSearchClipEditorPage };
    },
  });

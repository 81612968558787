import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import { AppLinkProps } from "./AppLink.model";

export const AppLink = forwardRef<HTMLAnchorElement, AppLinkProps>(
  ({ to, title, ...props }, ref) => {
    return (
      <Link
        ref={ref}
        underline="hover"
        component={RouterLink}
        to={to}
        children={props.children || title}
        {...props}
      />
    );
  }
);

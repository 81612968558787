import { AppRouteLazyBuilder } from "src/models/AppRouteLazy";

type PowerSearchRoutePathParams = {
  // no path params for now
};

type PowerSearchRouteQueryParams = {
  startDate: string;
  endDate: string;
};

export const powerSearchResultRoute = new AppRouteLazyBuilder<
  PowerSearchRoutePathParams,
  PowerSearchRouteQueryParams
>({
  path: "/power-search/result",
  lazy: async () => {
    const { PowerSearchResultPage } = await import("./PowerSearchResult.page");
    return { Component: PowerSearchResultPage };
  },
});

import * as MuiIcons from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { alpha, Button, Stack, Typography, useTheme } from "@mui/material";
import { PageLayoutMobile } from "src/components/PageLayoutMobile";
import { useIsMobile } from "src/utils/useIsMobile";
import { useIsUserRegistered } from "src/api/useIsUserRegistered";
import { PageLayoutDesktop } from "src/components/PageLayoutDesktop";
import { useSessionDelete } from "src/api/useSessionDelete";
import { watchListRoute } from "src/pages/WatchList/WatchList.route";

export const AccountExpiredPage = () => {
  const { palette } = useTheme();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const sessionDelete = useSessionDelete();

  // if registered and not expired users lands here - redirect him to WatchList
  const isUserRegistered = useIsUserRegistered();
  useEffect(() => {
    if (isUserRegistered) {
      navigate(watchListRoute.path, { replace: true });
    }
  }, [isUserRegistered, navigate]);

  const { logout } = useAuth0();
  const handleLogout = async () => {
    // wait for session deletion
    await sessionDelete.mutateAsync();

    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  if (isMobile) {
    return (
      <PageLayoutMobile
        hideGlobalSearchBtn
        hideTopBar
        content={
          <Stack width="100%" height="100%" justifyContent="center" px={2}>
            <Stack
              width={100}
              height={100}
              justifyContent="center"
              alignItems="center"
              alignSelf="center"
              bgcolor={alpha(palette.warning.main, 0.04)}
              borderRadius="50%"
              children={
                <MuiIcons.Warning
                  color="warning"
                  sx={{
                    fontSize: 64,
                  }}
                />
              }
            />

            <Typography variant="h6" textAlign="center">
              Your TVEyes Account has Expired
            </Typography>

            <Typography variant="subtitle1" mt={4} textAlign="center">
              Request trial extension for 15 days.
              <br /> Or contact the support team to renew your account.
            </Typography>

            <Stack gap={2} justifyContent="center" mt={4}>
              <Button
                variant="contained"
                color="info"
                children="Request Trial"
              />

              <Button
                variant="contained"
                color="secondary"
                onClick={handleLogout}
                children="Log Out"
              />
            </Stack>
          </Stack>
        }
      />
    );
  }

  const mailSubject = "Support Request: My TVEyes Insight Account has Expired";
  const mailBody = encodeURIComponent(
    [
      `Hello!`,
      `I've just tried to login to my TVEyes Insight Account and found that the account has been marked as Expired.`,
      `Could someone help me resolve this?`,
      `Thank you!`,
    ].join("\r\n")
  );

  const contactLink = `mailto:customersuccess@tveyes.com?subject=${mailSubject}&body=${mailBody}`;

  return (
    <PageLayoutDesktop
      content={
        <Stack m="auto" maxWidth={884} flex={1} justifyContent="center">
          <Stack
            width={100}
            height={100}
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            bgcolor={alpha(palette.warning.main, 0.04)}
            borderRadius="50%"
            children={
              <MuiIcons.Warning
                color="warning"
                sx={{
                  fontSize: 64,
                }}
              />
            }
          />

          <Typography variant="h4" textAlign="center" mt={2}>
            Your TVEyes Account has Expired.
          </Typography>

          <Typography
            variant="subtitle1"
            textAlign="center"
            mt={4}
            color={palette.text.secondary}
          >
            Request trial extension for 15 days.
            <br />
            Or contact the support team to renew your account.
          </Typography>

          <Stack direction="row" gap={4} justifyContent="center" mt={4}>
            <Button
              variant="contained"
              color="info"
              sx={{ width: 177 }}
              href="https://www.tveyes.com/free-trial-offer-tveyes-broadcast-tv-radio-media-monitoring/"
              target="_blank"
              children="Request Trial"
            />

            <Button
              variant="contained"
              color="secondary"
              onClick={handleLogout}
              children="Log Out"
              sx={{ width: 177 }}
            />

            <Button
              variant="contained"
              color="primary"
              sx={{ width: 177 }}
              href={contactLink}
              children="Contact Support"
            />
          </Stack>
        </Stack>
      }
    />
  );
};
